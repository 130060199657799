import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatCheckboxChange, MatDialog, MatTableDataSource, ThemePalette } from '@angular/material';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs/internal/Observable';
// import { of } from 'rxjs/operators';
import * as moment from 'moment';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { AuthService } from 'src/utils/auth.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import * as mom from 'moment-timezone';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { MessageService } from 'primeng-lts/api';
import { ClusterManager } from '@agm/markerclusterer';
import { ClusterIcon } from '@google/markerclustererplus/dist/cluster-icon';
import { colorConfig } from 'src/environments/color-configs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from 'src/environments/environment';
import { AgmInfoWindow, AgmMap, AgmPolygon, ControlPosition, LatLng, LatLngLiteral, MapsAPILoader, MouseEvent, PolyMouseEvent } from '@agm/core';
import { ColorEvent } from 'ngx-color';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MVCEvent } from '@agm/core/utils/mvcarray-utils';
import { DrawingControlOptions } from '@agm/drawing/google-drawing-types';
import { OverlayType } from '@agm/drawing';
import { HttpClient } from '@angular/common/http';
import { AgmDrawingManager } from '@agm/drawing'
import { concat } from 'rxjs-compat/operator/concat';
import { NominatimService } from 'src/app/api/nominatim.service';
import { DomSanitizer } from '@angular/platform-browser';
declare const google: any;


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy{
  // @ViewChild(MatSort, {static: true}) sort: MatSort;
  visionDates = [3, 5, 18]
  snowDates = [4, 8, 20]
  OrgDetails: any = {};
  drawControls = false;
  GIS: any = 1;
  isSuperPortal = false;
  routeUrlType: string = '';
  reportUrlType: string = '';
  allGeoJson = '';
  updatedGeoJsonObject: any = {};
  smartGridReportType: any = '';
  showgridTrucks: boolean = false;
  gridTrucks: any = [];
  gridReportRequestId: number = 0;
  routeCompleted = new MatTableDataSource();
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.routeCompleted.sort = value;
  }
  showSectionKml = false;
  showSectionCheckBox = false;
  sectionKmlUrl = '';
  @ViewChild('empTbSort', { static: true }) empTbSort = new MatSort();
  @ViewChild('infoWindow', { static: false }) infoWindow: AgmInfoWindow;
  @ViewChild('geoJsonInfoWindow', { static: false }) geoJsonInfoWindow: AgmInfoWindow;
  @ViewChild('gridGeoJsonInfoWindow', { static: false }) gridGeoJsonInfoWindow: AgmInfoWindow;
  @ViewChild('zoneInfoWindow', { static: false }) zoneInfoWindow: AgmInfoWindow;
  @ViewChild('kmzInfoWindow', { static: false }) kmzInfoWindow: AgmInfoWindow;
  @ViewChild('agmMap', { static: true }) agmMap: AgmMap;
  selectedGeojsonSmartGrid: any = null;
  polygon: any;
  showDrawActions: boolean = false;
  managerOptions = {
    drawingControl: true,
    drawingControlOptions: {
      position: ControlPosition.TOP_CENTER,
      drawingModes: []
    },
    polygonOptions: {
      draggable: true,
      editable: true
    },
    drawingMode: "polygon"
  };
  options: any = {
    lat: 33.5362475,
    lng: -111.9267386,
    zoom: 10,
    fillColor: '#DC143C',
    draggable: true,
    editable: true,
    visible: true
  };
  uploadGridData: any = {};
  selectedPolygon: any = {};
  clickedLatLng: any;
  selectedgJsonStreetData: any;
  lat = 41.101721;
  lng = -80.643667;
  zoom = 0;
  urlOrigin = '';
  showMap = false;
  selectedGridPoint: any;
  isReportGenerated = false;
  mapClass = 'map-container-50';
  endClass = 'end-container-24';
  activateColor: ThemePalette = 'accent';
  activateChecked = false;
  gridKmlzIndex: number = 2;
  gridPolygonzIndex: number = 1;
  formClass = 'form-container';
  rightPosition = 'right-32';
  isNowStartColor = '#c1c1c1';
  bgColor = 'rgb(253, 235, 224)';
  resetBg = 'rgb(241, 119, 46)';
  printBg = 'white';
  stoppageBtn = 'stoppageBtn'
  searchBg = 'rgb(221, 92, 15)';
  labelColor = 'black';
  checkBoxContainerClass = 'checkBoxContainer'
  thBg = '#e9ba9e';
  appVersion: number;
  boundaryUrl: any = '';
  pilotMode: number;
  orgName: string = '';
  polygonPaths: google.maps.LatLngLiteral[] = [];
  drawZones: boolean = false;
  visible: boolean = false;
  latChamp: number = 46.245399818615894;
  longChamp: number = -72.0323995083924;
  idd: number = 0;
  isSmartPaths: boolean = false;
  thColor = '#6b5548'
  domain = 'snowpaths';
  rowEven = 'row-orange';
  tealIcon = '';
  saltPealIcon = ''
  gifName = 'LoadingTruck';
  buttonTextColor = 'black';
  isNowStartColorChange = false;
  showArrowView = false;
  showSaltView = false;
  showSaltMarkerView = false;
  customView: string = '';
  streetActionType: string = '';
  streetActionTypes = [
    {
      label: "Include street(s)",
      value: "include"
    },
    {
      label: "Exclude street(s)",
      value: "exclude"
    }
  ];
  zoneByStreettype: string = '';
  zoneByStreettypes = [
    {
      label: "Add street(s)",
      value: "addStreets"
    },
    {
      label: "Create zone by street(s)",
      value: "createZoneByStreet"
    }
  ]
  viewTypes = [
    {
      label: "Road view",
      value: "road"
    },
    {
      label: "Vehicle view",
      value: "arrow"
    },
    {
      label: "Vision view Granular",
      value: "salterMarkerGranular"
    },
    {
      label: "Vision view Liquid",
      value: "salterMarkerLiquid"
    },
    {
      label: "Vision View with point to point",
      value: "salter"
    }
  ];
  viewTypesGrid = [
    {
      label: "Cell view",
      value: "road"
    },
    {
      label: "Vehicle view",
      value: "arrow"
    }
  ];
  viewTypesM = [
    {
      label: "Road view",
      value: "road"
    },
    {
      label: "Vehicle view",
      value: "arrow"
    }
  ]
  viewType = [];
  defaultView = "road"
  isNowTriggered = false;
  showArrow = false;
  isNowStopColor = '#dd5c0f';
  isNowColor = 'white'
  // isNow = false;
  isNowStopColorChange = false;
  MaxReportDays = 0;
  mapTruckdata: any = [];
  mapStoppageData: any;
  timeZoneOffset = mom.tz.guess();
  to: any;
  iconPath = {
    url: './assets/logos/Stop.png',
    scaledSize: {
      width: 30,
      height: 30
    }
  }
  truckIconPath = {
    url: 'https://spmapskmzdevva.s3.us-east-1.amazonaws.com/TruckIcons/DumpTruckWithPlow.svg',
    scaledSize: {
      width: 45,
      height: 45
    }
  }
  markerLat = 0;
  markerLng = 0;
  replayTruckPoints: google.maps.LatLngLiteral[] = [];
  truckReplaySpeed = 100;
  isReplayStarted: boolean = false;
  grassIconPath = {
    url: './assets/logos/grass-truck.jpg',
    scaledSize: {
      width: environment.smartpathIconSize,
      height: environment.smartpathIconSize
    }
  }
  timeZome = ''
  stoppagePopupData: any;
  mapType = 'roadmap'; //roadmap or satellite
  privatemapData = '';
  routeKmz = '';
  privateKmz = '';
  boundaryKmz = '';
  privateMapSettings: any;
  searchLoading = false;
  triggerCount = 0;
  tableData = [];
  sortedData = [];
  routeCols = ['zones', 'completedDistance', 'remainingDistance'];
  routeCols1 = ['zones', 'completedDistance', 'remainingDistance'];
  zoneCreationStatusList = ['Initiated', 'InsertedZoneToDB', 'InitiatedZoneStreetGeojsonCreation', 'CompletedZoneStreetGeojsonCreation', 'InitiatedMapZoneWithSections', 'CompletedMapZoneWithSections', 'InitiatedZoneStreetKMZCreation', 'CompletedZoneStreetKMZCreation']
  gridTableCols = ['GridZone', 'Completed', 'Remaining'];
  showRouteCompleted = false;
  showTrucks = true;
  showStops = false;
  showPins: boolean = true;
  showtruckPoints = false;
  zeroSalt = true;
  isIncludeInactiveZones = false;
  isInactiveZones = false;
  Sensor1Color = '';
  Sensor2Color = '';
  Sensor3Color = '';
  Sensor4Color = '';
  BothSensorsColor = '';
  routeStreetColor = '';

  reportData = {
    ShowSensor1: true,
    ShowSensor2: true,
    ShowSensor3: true,
    ShowSensor4: true,
    IsNow: false
  }
  rectangleSvg: string = '<svg width="290" height="160" xmlns="http://www.w3.org/2000/svg"><g><title>Layer 1</title>   <rect stroke="#000000" stroke-width="2" fill="none" x="5" y="5" width="25" height="25" id="imagebot_2"/>  </g>  <metadata>image/svg+xmlOpenclipartsimple green rectangle2010-08-29T19:40:48a simple green rectanglehttps://openclipart.org/detail/82723/simple-green-rectangle-by-mireillemireillegreenrectangle</metadata> </svg>';
  rectangleWidth: number | string;
  rectangleIndexes: [] | number;
  rectangleHeight: number;
  smartPathData = {};
  showGridReport: boolean = false;
  showSmartpathView: boolean = false;
  allPortalsTypes = [];
  allPortals = [];
  trucksTypesArray = [];
  allGridList: any = [];
  filteredGridList: any = [];
  activeGridList: any = [];
  inActiveGridList: any = [];
  gridListDropdownList: any = [];
  showReportKmz = false;
  routesArray = [];
  trucksArray = [];
  zonesArray = [];
  allTType = [{
    Id: 'all',
    Name: "all"
  }]
  allTrType = [{
    Id: 0,
    RouteName: "all"
  }]
  allTruck = [{
    Id: 'all',
    Description: "all"
  }]
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public customStyle = [{
    "featureType": "poi",
    "stylers": [{
      visibility: "off",
    }]
  },];

  showTable = false;
  selectedPortal: any;
  reportsArray = [];
  displayCols = ['zone', 'completed', 'remaining'];
  authResult: any;
  orgId: any = '';
  userEmail = '';
  lastVisitedPortal: any;
  userData: any = {};
  searchUrl = '';
  sortUrl = '';
  currentPage = 0;
  currentRow = 10;
  StartTime = new Date();
  StopTime = new Date();
  id: any;
  allTruckPositions = [];
  allSaltPositions = [];
  salterBubbleSize: number;
  salterDataColorRange: any;
  dropdownSettings: IDropdownSettings = {};
  transRoutedropdownSettings: IDropdownSettings = {};
  zoneDropdownSettings: IDropdownSettings = {};
  gridDropdownSettings: IDropdownSettings = {};
  gridObj: any = {};
  smartGridObj = {
    name: "",
    orgName: '',
    userEmail: '',
    opacity: 0.3,
    gridSize: 5,
    polygonFillColor: "#09DA09",
    cellFillColor: "#F21515",
    orgId: 0,
    portalId: 0,
    polygonCoordinates: []
  };
  routeGridObj = {
    name: "",
    orgId: 0,
    portalId: 0,
    truckTypeId: 0,
    fillColor: 'green',
    coordinates: []
  };
  gridSizeList: number[] = [];
  reportScreenType: string = '';
  geojsonPath: any;
  geoPayload: any;
  routeGeojson: any;
  userManualData: any;
  selectedZoneJobDetails: any = {};
  isTransportHub: boolean = false;
  showUploadAssign: boolean = false;
  showUploadForm: boolean = false;
  showAssignForm: boolean = false;
  showRouteStreets: boolean = false;
  transportRoutesList: any = [];
  excludeGeojsonData: any = {};
  OriginalexcludeGeojsonData: any = {};
  activeExcludeStreetZone: any = '';
  showExcludeStreets: boolean = false;
  selectedRoute = {};
  routeGjson = {};
  busStopLists = [];
  busReport: any = {};
  selectedTruckType: any = '';
  selectedTruck: any = [];
  showExclude: boolean = false;
  selectedExcludeRoute: any = '';
  excludeS3Url = '';
  smartPathGridGeojsonPayload: any = {};
  excludeStreetsData: any = [];
  newRoute = {
    color: '#0F9AF0',
    routeType: ''
  };
  routeTypeList = ['Linear','Circular']
  truckListExceptAll: any = [];
  calanderData: any = {};
  problemSitePins: any = [];
  username: string = '';
  excludeStreetColor: any = '#1423F5'
  showkmzInfoWindow: boolean = false;
  kmzInfoWindowLat: any;
  kmzInfoWindowLng: any;
  kmzInfoWindowHtml: any = '';
  public screenWidth: any;  
  public screenHeight: any;  
  streetPrioritiesData: any = {};
  message: string = '';
  priorityGeojsonData: any = {}
  OriginalpriorityGeojsonData: any = {}
  showPriorityStreet: boolean = false;
  showZoneByStreet: boolean = false;
  zoneByStreetsData: any = [];
  zoneByStreetColor: any = '#F514D0';
  selectedZoneGJsonData: any = {};
  addStreetZoneS3Url: string = '';
  showAddStreetZoneStreets: boolean = false;
  selectedAddStreetRoute: any = '';
  selectedZoneToCopy: any = {};
  deletePanelOpenState: boolean = false
  highlightTruckInZone: boolean = false;
  showHighlightTruckInZone: boolean = false;
  tokenValue: any = {};
  isMaster: boolean = false;
  salterAvailable: boolean = false;
  isLoginPage = false;
  currentUrl = '';
  routerUrl = '';

  uTCDatetime = mom.tz("2022-06-09 14:26:54", "UTC").format();
  constructor(private apiService: ApiService, private helper: HelperService, private dialog: MatDialog,
    private authService: AuthService, private router: Router, public datepipe: DatePipe, private nominatimService: NominatimService,
    private renderer: Renderer2, private el: ElementRef, private httpClient: HttpClient, private drawingManager: AgmDrawingManager,
    private messageService: MessageService, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,private ngZone: NgZone, private mapsAPILoader: MapsAPILoader, private location: Location) {
    for (let i = 1; i <= 25; i++) {
      this.gridSizeList.push(i * 5);
    }
    // this.sortedData = this.routeCompleted.slice();
    // this.to = this.datepipe('en-Us').transform('2022-06-09T14:26:54.000UTC', 'MM/dd/yyyy hh:mm tt', 'EST');
    this.to = mom.tz(this.uTCDatetime, "Eastern Standard Time").format("DD/MM/yyyy hh:mm A");
    var getText = "<b>910</b><br />Last Updated: 2022-06-09 14:26:54<br /> Speed: 0.0 mphnullnull"
    var textList = getText.split('Last Updated: ');
    var dateText = textList[1].split('<br />')[0]
    const uTCDatetime = mom.tz(dateText, "UTC").format();
    const to = mom.tz(this.uTCDatetime, "EST").format("MM/DD/yyyy hh:mm A");


    // var str = "Geeksforgeeks - Good Platform"; 

    // var re = /datetext/gi; 
    var regExp = new RegExp(dateText, 'gi');

    var newstr = getText.replace(regExp, to);

    const urlOrigin = window.location.origin;
    this.urlOrigin = window.location.origin;
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if (isFleetpathAvailable) {
      this.domain = 'fleetpaths';
    }
    if (isAvailable) {
      this.domain = 'streetpaths';
    }
    this.domain = this.apiService.domain?this.apiService.domain:this.apiService.getLocalStorage('domain');

    this.handlePortalStyles();
  }
  handlePortalStyles() {
    this.buttonTextColor = this.domain === 'snowpaths' ? colorConfig[this.domain]['buttonColorBlack'] :
      (this.domain === 'streetpaths' || this.domain === 'fleetpaths' || this.domain === 'mover') ? colorConfig[this.domain]['buttonColorBlack'] : 'black'
    this.gifName = colorConfig[this.domain]['reports']['loadingGifName'];
    this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
    this.checkBoxContainerClass = colorConfig[this.domain]['reports']['checkBoxContainerClass'];
    this.thBg = colorConfig[this.domain]['reports']['routeTableHeaderBg'];
    this.thColor = colorConfig[this.domain]['reports']['routeTableHeaderColor'];

    this.bgColor = colorConfig[this.domain]['reports']['background'];
    this.resetBg = colorConfig[this.domain]['reports']['resetBg'];
    this.printBg = colorConfig[this.domain]['reports']['printBg'];
    this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    this.labelColor = colorConfig[this.domain]['reports']['labelColor'];
    this.tealIcon = './assets/logos/' + this.domain + 'teal.png';
    this.saltPealIcon = './assets/logos/saltPeal.png'
  }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.route.queryParams.subscribe(
      params => {
        if (params && params.reportScreenType) {
          this.reportScreenType = params['reportScreenType'];
          this.gridObj = this.reportScreenType === 'grid' ? this.smartGridObj : this.routeGridObj;
          console.log("reportScreenType " + this.reportScreenType);
          this.managerOptions.drawingControlOptions.drawingModes.push('polygon')
        }
      }
    )
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        var jwtToken = session.getIdToken().getJwtToken();
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = Number(this.tokenValue['custom:organizationid']);
        this.username = this.tokenValue['cognito:username'];
        this.userEmail = this.tokenValue['email']
        this.reportData['OrgId'] = Number(this.orgId);
        console.log(btoa('14'))
        console.log(atob(btoa('14')))
        this.getUserDetails();
        // this.getMapDetails();
      } else {
        this.router.navigate[('/')]
      }
    });
    if (!this.authResult) {
      // alert("Hi")
      localStorage.clear();
      this.router.navigate[('/')]
    }

    // this.getMapDetails()
    // this.StartTime.
    this.StopTime.setMinutes(59);
    this.StopTime.setSeconds(0);
    this.StopTime.setHours(23);
    this.StartTime.setMinutes(0);
    this.StartTime.setSeconds(0);
    this.StartTime.setDate(this.StopTime.getDate());
    this.StartTime.setHours(0)
    // this.empTbSort.disableClear = true;
    this.routeCompleted.sort = this.sort;

  }
  ngOnDestroy() {
    var auth = this.apiService.getLocalStorage('isAuthenticated');
    if (auth) {
      this.apiService.addLocalStorage('domain', this.domain)
      if(!this.isMaster){
        this.apiService.addLocalStorage('reportData', JSON.stringify(this.reportData))
      }
      this.apiService.addLocalStorage('startTime', JSON.stringify(this.StartTime))
      this.apiService.addLocalStorage('stopTime', JSON.stringify(this.StopTime))
    } else {
      this.apiService.removeLocalStorage('reportData')
      this.apiService.removeLocalStorage('startTime')
      this.apiService.removeLocalStorage('stopTime')
    }
    if (this.id) {
      clearInterval(this.id);
    }
    // document.removeEventListener('visibilitychange', this.handleTabChange);
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      console.log('Tab is active '+this.selectedPortal);
      // Reset header & footer styles
      if(this.allPortals && this.allPortals.length >0){
        let objIndex = this.allPortals.findIndex((obj => obj.Id === this.selectedPortal));
        if(objIndex !== -1){
          this.domain = this.allPortals[objIndex]['PortalType'].toLowerCase();
          this.apiService.addLocalStorage('domain', this.domain)
          this.apiService.domain = this.domain;
        } else {
          this.domain = this.apiService.getLocalStorage('domain')
        }
      }else {
        this.domain = this.apiService.getLocalStorage('domain')
      }
      
      this.handlePortalStyles();
    }
  };
  async getUserDetails() {
    // var username = this.apiService.getLocalStorage('username');
    var username = this.tokenValue['cognito:username']
    this.username = username;
    if (username.includes("steven")) {
      this.showSectionCheckBox = true;
    }
    var url = 'user?name=' + this.username.toLowerCase() + '&page=0&size=10&orgId=' + this.orgId;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      console.log(data);
      if (data.users[0]) {
        this.lastVisitedPortal = Number(data.users[0].lastVisitedPortal);
        this.userData = data.users[0];
        this.apiService.addLocalStorage('activeAlertsId',this.userData.alertIds);
      }
      this.getMapDetails();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getMapDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.OrgDetails = data;
      this.boundaryUrl = data.boundaryUrl;
      this.appVersion = data.organization['Lite'];
      this.pilotMode = data.organization['Pilot'];
      this.orgName = data.organization['Name'];
      this.GIS = data.organization['GIS'];
      this.isSuperPortal = data.organization['ShowSuperPortal'];
      if (this.isSuperPortal) {
        this.allPortalsTypes.push({
          Id: 0,
          Name: "Super Portal"
        })
      }
      if (this.appVersion === 1) {
        this.mapClass = (this.appVersion === 1) ? 'map-container-80' : 'map-container-50';
        this.endClass = (this.appVersion === 1) ? 'end-container-0' : 'end-container-24';
        this.hideTable();
      }
      // if(data.organization['SmartPaths'] === 1){
      //   // this.allGeoJson=data.organization['SmartPathGridGeojsonS3Url'];
      //   this.mapClass='map-container-80';
      //   this.endClass='end-container-0'
      //   this.isSmartPaths = true;
      // }
      this.lat = data['siteSetting']['CenterLatitude'];
      this.lng = data['siteSetting']['CenterLongitude'];
      this.markerLat = data['siteSetting']['CenterLatitude'];
      this.markerLng = data['siteSetting']['CenterLongitude'];
      this.zoom = data['siteSetting']['zoomLevel'];
      var preZoom = this.apiService.getLocalStorage('mapZoom');
      if (preZoom) {
        this.zoom = Number(preZoom)
      }
      this.getPortals();

    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  selectedGridData(key: any, value: any) {
    this.gridObj[key] = Number(value);
    if (key === 'portalId') {
      this.getTruckTypes(value);
    }
  }
  addSelected(key: any, value: any) {

    this.reportData[key] = value;
    if (value !== 'all' && (key === 'RouteId' || key === 'TruckId' || key === 'Trucktype' || key === 'PortalId')) {
      this.reportData[key] = Number(value);
    }
    if (key === 'Trucktype') {
      this.apiService.removeLocalStorage('reportData')
      this.getTrucks()
    }
    console.log(JSON.stringify(this.reportData))
  }
  getTransportTrucks(value: any) {
    this.selectedTruckType = value;
    var url = 'transport?orgId=' + this.orgId + '&portalId=' + this.selectedPortal + '&unassignedBuses=true&busTypeId=' + value;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      // this.getStatusKey();
      this.truckListExceptAll = data;
      this.dropdownSettings = {
        idField: 'Id',
        textField: 'Description',
        enableCheckAll: false,
        allowSearchFilter: true
      };

    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getSearch() {
    this.apiService.isPendingResponse = true;
    this.searchLoading = true;
    // this.showMap = false;
    if(this.reportData['TruckId'].length === 1 && this.reportData['TruckId'][0]['Id'] !== 'all' && this.reportData['TruckId'][0]['Id'] !== 0){
      var selectedTruckDetails = {};
      selectedTruckDetails = this.trucksArray.filter(truck => {
        return truck.Id === this.reportData['TruckId'][0]['Id']
      })
      if(selectedTruckDetails[0]['IsSaltTruck'] === 1){
        this.salterAvailable = true;
      } else {
        this.salterAvailable = false;
      }
    }
    this.viewType = (this.reportData['TruckId'].length === 1 && this.salterAvailable) ? this.viewTypes : this.viewTypesM;
    var payload = Object.assign({}, this.reportData);
    payload['StartTime'] = this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000')
    payload['StopTime'] = this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000')
    console.log(this.reportData);
    if (payload['Trucktype'] === 'all') { payload['Trucktype'] = 0 }
    var truckIds = []
    this.reportData['TruckId'].map(truck => {
      truckIds.push(truck.Id)
    })
    payload['TruckId'] = truckIds.includes('all') ? [0] : truckIds;
    var routeIds = []
    this.reportData['RouteId'].map(truck => {
      routeIds.push(truck.Id)
    })
    if (routeIds.includes('all')) {
      payload['RouteId'] = null;
    } else {
      payload['RouteId'] = routeIds
    }
    var url = 'privateReport';
    this.geoPayload = payload;
    await this.apiService.postNewReportdata(url, payload, true).subscribe(
      (res: any) => {
        console.log(res);
        if (!res) {
          this.apiService.hideLoader()
        } else {
          this.privatemapData = res;
          var allTruckIds = [];
          this.reportData['TruckId'].map(truck => {
            allTruckIds.push(truck.Id)
          })
          if (allTruckIds.includes('all') || allTruckIds.includes(0)) {
            this.getPrivateTrucks();
          } else {
            this.getAllPositions();
          }
        }
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getPrivateData() {
    this.reportUrlType = '';
    this.apiService.showLoader();
    var url = 'privateReport';
    var Payload = {
      "PortalId": this.selectedPortal,
      "OrgId": Number(this.orgId),
      "trucksInZone": this.highlightTruckInZone,
      "reportReqId": this.privatemapData['Id']
    }
    var routeIds = []
    this.reportData['RouteId'].map(truck => {
      routeIds.push(truck.Id)
    })
    if (routeIds.includes('all')) {
      Payload['RouteId'] = null
    } else {
      Payload['RouteId'] = routeIds
    }
    // this.salterAvailable = false;
    await this.apiService.postNewReportdata(url, Payload, true).subscribe(
      (res: any) => {
        // this.privateMapSettings = {};
        this.privateMapSettings = res;
        this.replayTruckPoints= res['breadCrumbData'];
        if (this.privateMapSettings.reportUrl.length && this.privateMapSettings.reportUrl.length > 0) {
          this.privateMapSettings.reportUrl.map((url) => {
            if(url !== '' && !this.reportUrlType){
              this.reportUrlType = url.split('.').pop();
              }
          })
        }
        if (this.privateMapSettings.routeUrl !== '' && this.privateMapSettings.routeUrl.length && this.privateMapSettings.routeUrl.length > 0) {
          this.routeUrlType = this.privateMapSettings.routeUrl[0].split('.').pop();
        }
        this.getStopTableData();
        if (!this.reportData.IsNow) {
          this.searchLoading = false;
        }
        
        if (this.appVersion === 1 && (this.reportData['TruckId'][0]['Id'] !== 'all' && this.reportData['TruckId'][0]['Id'] !== 0)) {
          if (this.salterAvailable) {
            this.defaultView = "salter";
            this.showArrowView = false;
            this.showSaltMarkerView = false;
            this.showSaltView = true;
          }
          if (!this.salterAvailable) {
            this.viewType = this.viewType.filter(obj => {
              return obj.value !== 'salter' && obj.value !== 'salterMarkerGranular' && obj.value !== 'salterMarkerLiquid'
              

            })
            this.defaultView = 'arrow'
            this.showSaltView = false;
            this.showSaltMarkerView = false;
            this.showArrowView = true;
          }
        }
        if (this.appVersion === 1 &&
          (this.reportData['TruckId'][0]['Id'] === 'all' || this.reportData['TruckId'][0]['Id'] === 0)) {
          this.defaultView = "road";
          this.showArrowView = false;
          this.showSaltView = false;
          this.showSaltMarkerView = false;
          this.showArrow = false;
        }
        this.showMap = true;
        // this.showSaltView = true;
        if (this.pilotMode === 1 && this.salterAvailable) {
          this.defaultView = 'arrow';
          this.showSaltView = false;
          this.showSaltMarkerView = false;
          this.showArrowView = true;
          this.viewType = this.viewType.filter(obj => {
            return obj.value !== 'road'
          })
        }
        if (this.pilotMode === 1 && !this.salterAvailable) {
          this.defaultView = 'arrow';
          this.showSaltView = false;
          this.showSaltMarkerView = false;
          this.showArrowView = true;
          this.showArrow = false;
        }
        this.isReportGenerated = true;
        this.apiService.isPendingResponse = false;
        if (this.privateMapSettings.routeUrl === '' || this.privateMapSettings.routeUrl.length == 0) {
          this.showMap = true;
          this.showReportKmz = true;
          this.apiService.hideLoader();
        } else {
          setTimeout(() => {
            this.showMap = true;
            this.showReportKmz = true;
            this.apiService.hideLoader();
          }, 4000);
        }
      }, (error) => {
        console.log(error);
        if (!this.reportData.IsNow) {
          this.searchLoading = false;
        }
        this.triggerCount = this.triggerCount + 1;
        if (this.triggerCount <= 5) {
          setTimeout(() => {
            this.getPrivateData();
          }, 10000);
        } else {
          this.triggerCount = 0;
          this.apiService.isPendingResponse = false;
          this.apiService.hideLoader();
          this.helper.openSnackBar(error, "OK")
        }
      }
    )
    this.reportData['PortalId'] = this.selectedPortal;
    if(!this.isMaster){
      this.apiService.addLocalStorage('reportData', JSON.stringify(this.reportData))
    }
    this.apiService.addLocalStorage('startTime', JSON.stringify(this.StartTime))
      this.apiService.addLocalStorage('stopTime', JSON.stringify(this.StopTime))
    // this.getPrivateTrucks();
  }
  async getAllPositions() {
    var url = 'allPositionPrivateReport';
    var truckIds = []
    this.reportData['TruckId'].map(truck => {
      truckIds.push(truck.Id)
    })
    var Payload = {
      "ShowSensor1": this.reportData.ShowSensor1,
      "ShowSensor2": this.reportData.ShowSensor2,
      "ShowSensor3": this.reportData.ShowSensor3,
      "ShowSensor4": this.reportData.ShowSensor4,
      "StartTime": this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000'),
      "StopTime": this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000'),
      "TruckId": truckIds,
      "OrgId": Number(this.orgId)
    }
    await this.apiService.postNewReportdata(url, Payload, true).subscribe(
      (res: any) => {
        console.log(res);
        this.allTruckPositions = res;
        this.mapTruckdata = [];
        res.map(truck => {
          if (truck.truckInfo) {
            this.mapTruckdata.push(truck.truckInfo)
            this.markerLat = truck.truckInfo.lat;
            this.markerLng = truck.truckInfo.lng;
            // this.replayTruckPoints = truck.truckPoints;
          }
        })
        console.log("mapTruckdata " + this.mapTruckdata)
        this.showTrucks = true;
        // if(res.truckInfo && res.truckInfo.length >0){
        // }
        if (!this.reportData['TruckId'].includes('all') && !this.reportData['TruckId'].includes(0) && !this.isSmartPaths) {
          // this.getSalterData();
          var allTruckIds = [];
          this.reportData['TruckId'].map(truck => {
            allTruckIds.push(truck.Id)
          })
          if (!allTruckIds.includes('all') && !allTruckIds.includes(0)) {
            this.getPrivateTrucks();
            if (!this.isNowTriggered) {
              if (this.appVersion === 1) {
                this.showArrowView = false
                this.defaultView = "salter"
                this.showSaltView = true;
              }
              else {
                this.showArrowView = true;
              }
            }
  
          }
          else {
            this.getPrivateData();
          }
        }
        if (this.isSmartPaths) {
          this.getGridZoneTableData();
        }
        if (!this.isNowTriggered) {
          this.defaultView = "arrow"
          this.showArrow = true;
          this.showArrowView = true;
          this.showtruckPoints = true;
          this.isReportGenerated = true;
        }
        
        if (this.isSmartPaths) {
          this.viewType = this.viewTypesGrid;
        }
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getSalterData() {
    var salterViewType = this.defaultView === 'salterMarkerGranular'?'Granular':this.defaultView === 'salterMarkerLiquid'?'LiqudAntiIce':
    'Granular';
    if(this.defaultView === 'salterMarkerGranular' || this.defaultView === 'salterMarkerLiquid'){
      this.showSaltMarkerView = false;
    }
    if(this.defaultView === 'salter'){
      this.showSaltView = false;
      salterViewType = 'Granular'
    }
    var url = 'salterData?hideZeroSalt=' + !this.zeroSalt+'&saltType='+salterViewType;
    var truckIds = [];
    // var salterAvailable = false;
    this.reportData['TruckId'].map(truck => {
      truckIds.push(truck.Id)
    })
    var Payload = {
      "StartTime": this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00'),
      "StopTime": this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00'),
      "TruckId": truckIds,
      "OrgId": Number(this.orgId)
    }
    await this.apiService.postNewReportdata(url, Payload, false).subscribe(
      (res: any) => {
        console.log(res);
        this.salterBubbleSize = Number(res[0]['salterBubbleSize']);
        this.allSaltPositions = res;
        // console.log("Dummy salter data "+JSON.stringify(this.allSaltPositions))
        this.salterDataColorRange = res[0]['salterDataColorRange'];
        // this.allSaltPositions.map(obj => {
        //   if (obj.salterData && obj.salterData.length > 0) {
        //     salterAvailable = true;
        //   }
        // })
        // if (!salterAvailable) {
        //   this.viewType = this.viewType.filter(obj => {
        //     return obj.value !== 'salter' && obj.value !== 'salterMarkerGranular' && obj.value !== 'salterMarkerLiquid'
        //   })
        //   this.defaultView = 'arrow'
        // }
        // this.showSaltMarkerView = true;
        if(this.defaultView === 'salterMarkerGranular' || this.defaultView === 'salterMarkerLiquid'){
          this.showSaltMarkerView = true;
        }
        if(this.defaultView === 'salter'){
          this.showSaltView = true;
        }

        // var allTruckIds = [];
        // this.reportData['TruckId'].map(truck => {
        //   allTruckIds.push(truck.Id)
        // })
        // if (!allTruckIds.includes('all') && !allTruckIds.includes(0)) {
        //   this.getPrivateTrucks();
        //   if (!this.isNowTriggered) {
        //     if (this.appVersion === 1) {
        //       this.showArrowView = false
        //       this.defaultView = "salter"
        //       this.showSaltView = true;
        //     }
        //     else {
        //       this.showArrowView = true;
        //     }
        //   }

        // }
        // else {
        //   this.getPrivateData();
        // }
        // this.showtruckPoints = true;
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getZeroSalterData() {
    var url = 'salterData?hideZeroSalt=' + !this.zeroSalt+ '&saltType=';
    var truckIds = [];
    if (this.isSmartPaths) {
      this.reportData['TruckId'].map(truck => {
        truckIds.push(truck.Id)
      })
    } else {
      truckIds.push(this.reportData['TruckId'][0]['Id'])
    }
    var Payload = {
      "StartTime": this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00'),
      "StopTime": this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00'),
      "TruckId": truckIds,
      "OrgId": Number(this.orgId)
    }
    await this.apiService.postNewReportdata(url, Payload, false).subscribe(
      (res: any) => {
        console.log(res);
        this.salterBubbleSize = Number(res[0]['salterBubbleSize']);
        this.allSaltPositions = res;
        this.salterDataColorRange = res[0]['salterDataColorRange'];
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getPrivateTrucks() {
    var payload = Object.assign({}, this.reportData);
    if (payload['Trucktype'] === 0 || payload['Trucktype'] === 'all') { payload['Trucktype'] = null }
    var truckIds = []
    this.reportData['TruckId'].map(truck => {
      truckIds.push(truck.Id)
    })
    payload['TruckId'] = truckIds.includes('all') ? [0] : truckIds;
    if (payload['RouteId'] === 0 || payload['RouteId'] === 'all') { payload['RouteId'] = null }
    payload['RouteId'] = this.reportData['RouteId'][0]['Id'] === 'all' ? null : this.reportData['RouteId'][0]['Id']
    payload['StartTime'] = this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000');
    payload['StopTime'] = this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000');
    var url = 'privateReportTruck'
    await this.apiService.postNewReportdata(url, payload, true).subscribe(
      (res: any) => {
        this.mapTruckdata = [];
        this.mapTruckdata = res['trucks'];
        this.mapStoppageData = res['truckStoppage'];
        this.timeZome = res['timeZone'];
        this.stoppagePopupData = res['stoppageReport'];
        this.showTrucks = true;
        this.getPrivateData();
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
        this.helper.openSnackBar(error, "OK");
      }
    )
    if (this.reportData['Trucktype'] === null) { this.reportData['Trucktype'] = "all" }
    if (this.reportData['TruckId'].includes(null)) {
      this.reportData['TruckId'] = [];
      this.reportData['TruckId'].push("all")
    }
    if (this.reportData['RouteId'] === null) { this.reportData['RouteId'] = "all" }
  }
  reset() {
    this.reportData['Trucktype'] = 'all';
    this.reportData['polygonGridId'] = 'all';
    this.reportData['RouteId'] = this.allTType;
    this.reportData['TruckId'] = [];
    this.reportData['TruckId'] = this.allTruck;

    this.reportData['ShowSensor1'] = true;
    this.reportData['ShowSensor2'] = true;
    this.reportData['ShowSensor3'] = true;
    this.reportData['ShowSensor4'] = true;
    this.StartTime = new Date();
    this.StopTime = new Date();
    this.StopTime.setMinutes(59);
    this.StopTime.setSeconds(0);
    this.StopTime.setHours(23);
    this.StartTime.setMinutes(0);
    this.StartTime.setSeconds(0);
    this.StartTime.setDate(this.StopTime.getDate());
    this.StartTime.setHours(0)
  }
  hideForm() {
    this.formClass = this.formClass === 'form-container' ? 'form-container-0' : 'form-container';
    this.mapClass = (this.formClass === 'form-container' && this.endClass === 'end-container-0') ?
      'map-container-80' : (this.formClass === 'form-container-0' && this.endClass === 'end-container-24') ?
        'map-container-70' : (this.formClass === 'form-container' && this.endClass === 'end-container-24') ?
          'map-container-50' : 'map-container-100';
    this.rightPosition = (this.formClass === 'form-container' && this.endClass === 'end-container-0') ? 'right-3' : (this.formClass === 'form-container-0' && this.endClass === 'end-container-0') ? 'right-4' : 'right-32';
  }
  hideTable() {
    this.endClass = (this.endClass === 'end-container-0') ? 'end-container-24' : 'end-container-0';
    // this.mapClass= (this.endClass==='end-container-24' || this.formClass==='form-container' 
    // )?'map-container-80':(this.formClass==='form-container-0' && this.endClass === 'end-container-0')?
    // 'map-container-100':'map-container-50';
    this.mapClass = (this.formClass === 'form-container' && this.endClass === 'end-container-0') ?
      'map-container-80' : (this.formClass === 'form-container-0' && this.endClass === 'end-container-24') ?
        'map-container-70' : (this.formClass === 'form-container' && this.endClass === 'end-container-24') ?
          'map-container-50' : 'map-container-100';
    // }
    this.rightPosition = (this.formClass === 'form-container' && this.endClass === 'end-container-0') ? 'right-3' : (this.formClass === 'form-container-0' && this.endClass === 'end-container-0') ? 'right-4' : 'right-32';
  }
  getTableData() {
    this.showTable = true;
  }
  //Portal dropdown change event
  getportalId(portalId) {
    // this.showPage = false;
    console.log(portalId);
    this.privateMapSettings = {};
    this.showArrow = false;
    this.showTrucks = false;
    this.isReportGenerated = false;
    this.showtruckPoints = false;
    this.showArrowView = false;
    this.showSaltView = false;
    this.showSmartpathView = false;
    this.showReportKmz = false;
    this.selectedPortal = Number(portalId);
    var selectedPortalObj = this.allPortals.find(obj => {
      return obj.Id === this.selectedPortal;
    })
    console.log(selectedPortalObj)
    this.getIsHighlightTruckInZone();
    this.isTransportHub = selectedPortalObj.IsTransportHub ? true : false;
    this.mapClass = (this.isTransportHub || this.appVersion === 1) ? 'map-container-80' : 'map-container-50';
    this.endClass = (this.isTransportHub || this.appVersion === 1) ? 'end-container-0' : 'end-container-24';
    if (selectedPortalObj['Latitude'] && selectedPortalObj['Longitude'] && selectedPortalObj['Latitude'] !== 0 && selectedPortalObj['Longitude'] !== 0) {
      this.lat = selectedPortalObj['Latitude'];
      this.lng = selectedPortalObj['Longitude'];
    }
    if (selectedPortalObj['ZoomLevel'] && selectedPortalObj['ZoomLevel'] !== 0) {
      this.zoom = selectedPortalObj['ZoomLevel'];
    }
    if (selectedPortalObj['SmartPaths'] === 1) {
      // this.mapClass='map-container-80';
      // this.endClass='end-container-0'
      this.isSmartPaths = true;
      this.getSmartpathPolygonData()
    } else {
      this.isSmartPaths = false;
      if (this.isTransportHub) {
        this.getTransportRoutes();
      } else {
        if (this.GIS === 1) {
          this.getRoutes();
        } else {
          this.getRoutesPolygonData();
        }
      }
    }
    if (selectedPortalObj.Id !== 0) {
      if (selectedPortalObj['PortalType']) {
        this.domain = selectedPortalObj['PortalType'].toLowerCase();
      }
      this.apiService.addLocalStorage('domain', this.domain)
      this.apiService.addLocalStorage('isSmartpath', this.isSmartPaths)
      this.isNowStopColor = colorConfig[this.domain]['reports']['nowBg']
      this.handlePortalStyles();
      this.MaxReportDays = selectedPortalObj['MaxReportDays'] ? selectedPortalObj['MaxReportDays'] : 1;
      this.apiService.removeLocalStorage('reportData')
      this.updateLastVisitedPortal();
    } else {
      this.lat = this.OrgDetails['siteSetting']['CenterLatitude'];
      this.lng = this.OrgDetails['siteSetting']['CenterLongitude'];
      this.zoom = this.OrgDetails['siteSetting']['zoomLevel'];
    }
    this.reportData['PortalId'] = Number(portalId);
    // this.getTruckTypes();
    // this.getTrucks();
    // this.getAlertMessage(portalId);
  }
  insertAndShift(arr, from, to) {
    let cutOut = arr.splice(from, 1)[0]; // cut the element at index 'from'
    arr.splice(to, 0, cutOut);            // insert it at index 'to'
  }
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    var selectedPortalObj: any = {}
    this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        if (res['portals'].length > 0) {
          this.allPortals = this.allPortalsTypes.concat(res['portals']);
        } else {
          this.allPortals = this.allPortalsTypes;
        }
        if (this.lastVisitedPortal && this.lastVisitedPortal !== undefined) {
          this.reportData['PortalId'] = this.lastVisitedPortal;
          var objIndex = res['portals'].findIndex((obj => obj.Id === this.lastVisitedPortal));
          if(objIndex !== -1){
            this.domain = res['portals'][objIndex]['PortalType'].toLowerCase();
          }
          this.apiService.addLocalStorage('domain', this.domain)
          this.isNowStopColor = colorConfig[this.domain]['reports']['nowBg']
          this.handlePortalStyles()
          this.insertAndShift(this.allPortals, objIndex, 0)
          this.selectedPortal = this.lastVisitedPortal;
          console.log("Before update: ", this.allPortals)
        } else {
          this.domain = res['portals'][0]['PortalType'] ? res['portals'][0]['PortalType'].toLowerCase() : res['portals'][1]['PortalType'].toLowerCase();
          this.apiService.addLocalStorage('domain', this.domain)
          this.handlePortalStyles()
          this.selectedPortal = res['portals'][0]['Id'];
        }
        if (this.reportScreenType) {
          this.drawControls = true;
          if(!this.isMaster){
            var existData = JSON.parse(this.apiService.getLocalStorage('reportData'));
            this.selectedPortal = existData.PortalId;
          }
          let objIndex = this.allPortals.findIndex((obj => obj.Id === this.selectedPortal));
          this.domain = this.allPortals[objIndex]['PortalType'].toLowerCase();
          this.apiService.addLocalStorage('domain', this.domain)
          this.handlePortalStyles()
        }
        selectedPortalObj = this.allPortals.find(obj => {
          return obj.Id === this.selectedPortal;
        })
        this.isTransportHub = selectedPortalObj && selectedPortalObj.IsTransportHub ? true : false;
        this.mapClass = (this.isTransportHub || this.appVersion === 1) ? 'map-container-80' : 'map-container-50';
        this.endClass = (this.isTransportHub || this.appVersion === 1) ? 'end-container-0' : 'end-container-24';
        this.apiService.addLocalStorage('isSmartpath', this.isSmartPaths)
        if (selectedPortalObj && selectedPortalObj['SmartPaths'] === 1) {
          this.getSmartpathPolygonData();
        } else {
          if (this.reportScreenType) {
            this.getInstructionsManuals();
            // this.getRoutesPolygonData();
          } else {
            if (this.GIS === 1 && !this.isTransportHub) {
              this.getRoutes();
            } else {
              if (this.isTransportHub) {
              this.getTransportRoutes();
            } else {
              this.getRoutesPolygonData();
            }
          }
          }
        }
        this.reportData['PortalId'] = this.allPortals[0]['Id'];


        this.MaxReportDays = this.allPortals[0]['MaxReportDays'] ? this.allPortals[0]['MaxReportDays'] : 1;
        this.showMap = true;
        this.getIsHighlightTruckInZone();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getSmartpathPolygonData() {
    this.gridDropdownSettings = {
      idField: 'Id',
      textField: 'Name',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    var url = 'smartPathPolygonGrid?portalId=' + this.selectedPortal;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.getTruckTypes(this.selectedPortal);
      this.allGridList = data.polygons;
      if (data['polygons'].length > 0) {
        this.activeGridList = data.polygons.filter(grid => {
          return grid.Active === true
        })
        this.inActiveGridList = data.polygons.filter(grid => {
          return grid.Active === false
        })
        if (this.reportScreenType) {
          this.filteredGridList = this.allGridList;
        } else {
          this.filteredGridList = this.activeGridList;
        }
        this.gridListDropdownList = this.allTType.concat(this.filteredGridList);
      } else {
        this.gridListDropdownList = this.allTType;
      }
      this.isSmartPaths = true;
      this.reportData['polygonGridId'] = this.allTType[0]['Id'];
      this.apiService.addLocalStorage('isSmartpath', this.isSmartPaths)
    }, (error) => {
      console.log(error);
    })
  }
  async getRoutesPolygonData() {
    this.filteredGridList = [];
    this.gridDropdownSettings = {
      idField: 'Id',
      textField: 'Name',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.zoneDropdownSettings = {
      idField: 'Id',
      textField: 'Name',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    var url = 'RouteNonGis?orgId=' + this.orgId + '&portalId=' + this.selectedPortal;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.getTruckTypes(this.selectedPortal);
      this.allGridList = data.routes;
      if (data['routes'].length > 0) {
        this.activeGridList = data.routes.filter(grid => {
          return grid.Active === true
        })
        this.inActiveGridList = data.routes.filter(grid => {
          return grid.Active === false
        })
        if (this.reportScreenType) {
          this.filteredGridList = this.allGridList;
        } else {
          this.filteredGridList = this.activeGridList;
        }
        this.gridListDropdownList = this.allTType.concat(this.filteredGridList);
        if (this.GIS === 0) {
          if (this.allGridList.length > 0) {
            this.allGridList = this.allTType.concat(data['routes']);
          } else {
            this.allGridList = this.allTType;
          }
          if (this.allGridList.length > 0) {
            this.allGridList = this.allGridList.filter(function (obj) {
              return obj.Name !== 'Any';
            });
          }
          this.reportData['RouteId'] = [];
          this.reportData['RouteId'].push(this.allGridList[0]);
        }
      } else {
        this.allGridList = this.allTType;
        this.reportData['RouteId'] = [];
        this.reportData['RouteId'].push(this.allGridList[0]);
        this.gridListDropdownList = this.allTType;
      }
    }, (error) => {
      console.log(error);
    })
  }
  onPolygonClick(event: MouseEvent, getPolygonDetails: any) {
    if (this.reportScreenType === 'grid') {
      this.getgridUploadDetails(getPolygonDetails.Id)
    }
    this.clickedLatLng = {
      lat: event['latLng'].lat(),
      lng: event['latLng'].lng()
    };
    this.selectedPolygon = getPolygonDetails;
    this.activateChecked = getPolygonDetails['Active'];
    console.log('Polygon clicked at:', this.clickedLatLng);
    this.changeDetectorRef.detectChanges();
    if (this.gridGeoJsonInfoWindow && (!this.gridGeoJsonInfoWindow.isOpen || this.gridGeoJsonInfoWindow.isOpen)) {
      this.gridGeoJsonInfoWindow.close()
    }
    // Open the info window or perform any other actions with the latLng
    if (this.infoWindow && (!this.infoWindow.isOpen || this.infoWindow.isOpen)) {
      this.infoWindow.open()
    }
    this.infoWindow.open();
    // }
  }
  onZonePolygonClick(event: MouseEvent, getPolygonDetails: any) {
    this.clickedLatLng = {
      lat: event['latLng'].lat(),
      lng: event['latLng'].lng()
    };
    this.selectedPolygon = getPolygonDetails;
    console.log('Polygon clicked at:', this.clickedLatLng);
    this.getZoneById(getPolygonDetails);

    // }
  }
  getZoneById(getPolygonDetails: any) {
    this.selectedZoneJobDetails = {};
    var url = 'jobs?routeId=' + getPolygonDetails.Id;
    this.apiService.getByUrl(url).subscribe(async (data: any) => {
      this.selectedZoneJobDetails = data['jobs'][0];
      if (this.selectedZoneJobDetails && this.selectedZoneJobDetails.Status) {
        this.selectedZoneJobDetails['completedPercentage'] = ((this.zoneCreationStatusList.indexOf(this.selectedZoneJobDetails.Status) + 1) / 8 * 100)
        console.log(this.selectedZoneJobDetails);
      } else {
        this.selectedZoneJobDetails = {};
        this.selectedZoneJobDetails['completedPercentage'] = 100
      }
      this.changeDetectorRef.detectChanges();
      // Open the info window or perform any other actions with the latLng
      if (!this.zoneInfoWindow.isOpen || this.zoneInfoWindow.isOpen) {
        this.zoneInfoWindow.open()
      }
      this.zoneInfoWindow.open();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getgridUploadDetails(polygonId) {
    var url = 'smartPathPolygonGrid?polygonGridId=' + polygonId;
    this.apiService.getByUrl(url).subscribe(async (data: any) => {
      console.log(data)
      this.uploadGridData = await data;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getTruckTypes(portalId: any) {
    var url = 'truckType?orgId=' + this.orgId + '&portalId=' + Number(portalId);
    this.apiService.getByUrl(url).subscribe(async (data: any) => {
      if(this.reportScreenType !== "street"){
        if (data.length > 0) {
          this.trucksTypesArray = this.allTType.concat(data);
        } else {
          this.trucksTypesArray = this.allTType;
        }
        this.reportData['Trucktype'] = this.trucksTypesArray[0]['Id'];
        this.getTrucks();
      this.problemSitePins = await this.helper.getbyUrl('siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal);
      this.getPins();
      } else {
        this.trucksTypesArray = data;
        if (data[0]) {
          this.gridObj.truckTypeId = data[0]['Id']
        }
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getRoutes() {
    this.zoneDropdownSettings = {
      idField: 'Id',
      textField: 'Name',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    var url = 'route?orgId=' + this.orgId + '&portalId=' + this.selectedPortal;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.getTruckTypes(this.selectedPortal);
      if (data['routes'].length > 0) {
        this.routesArray = this.allTType.concat(data['routes']);
      } else {
        this.routesArray = this.allTType;
      }
      if (this.routesArray.length > 0) {
        this.routesArray = this.routesArray.filter(function (obj) {
          return obj.Name !== 'Any';
        });
      }
      // this.reportData['RouteId'] = this.routesArray[0];
      this.reportData['RouteId'] = []
      this.reportData['RouteId'].push(this.routesArray[0])
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getPins() {
    var url = 'siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.problemSitePins = data;
      this.getTrucks();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    // this.getStopTableData();
  }
  async getTrucks() {
    var url = 'truck?orgId=' + this.orgId + '&portalId=' + this.selectedPortal + '&truckType=' + this.reportData['Trucktype']
      + '&includeInactiveTrucks=true';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      // this.getStatusKey();
      this.truckListExceptAll = data;
      this.dropdownSettings = {
        idField: 'Id',
        textField: 'Description',
        enableCheckAll: false,
        allowSearchFilter: true
      };
      // IsSaltTruck
      data.map((truck: any) => {
        if (truck.IsSaltTruck === 1) {
          truck.Description += "-vision"
        }
      })
      if (data.length > 0) {
        this.trucksArray = this.pilotMode !== 1 ? this.allTruck.concat(data) : data;
      } else {
        this.trucksArray = this.pilotMode !== 1 ? this.allTruck : [];
      }
      this.reportData['TruckId'] = []
      this.reportData['TruckId'].push(this.trucksArray[0])
      if(!this.isMaster){
      var existingData = JSON.parse(this.apiService.getLocalStorage('reportData'))
      }
      if (this.apiService.getLocalStorage('startTime')) {
        this.StartTime = new Date(JSON.parse(this.apiService.getLocalStorage('startTime')))
        this.apiService.removeLocalStorage('startTime')
      }
      if (this.apiService.getLocalStorage('stopTime')) {
        this.StopTime = new Date(JSON.parse(this.apiService.getLocalStorage('stopTime')))
        this.apiService.removeLocalStorage('stopTime')
      }
      if (existingData) {

        for (let key in existingData) {
          if (!this.selectedPortal && key === 'PortalId') {
            this.reportData['PortalId'] = this.selectedPortal;
          } else {
            this.reportData[key] = existingData[key]
          }
          if (key === "TruckId") {
            this.reportData[key] = Object.assign([], existingData[key])
          }
        }
        if (this.isSmartPaths) {
          if (!this.reportData['polygonGridId'].includes('all')) {
            this.onSmartZoneSelect('polygonGridId', this.reportData['polygonGridId'])
          } else {
            this.reportData['polygonGridId'].push('all');
          }
        }
        if (this.lastVisitedPortal && this.selectedPortal) {
          this.selectedPortal = this.lastVisitedPortal;
        }
        if (this.reportScreenType) {
          this.selectedPortal = existingData.PortalId;
          let objIndex = this.allPortals.findIndex((obj => obj.Id === this.selectedPortal));
          this.domain = this.allPortals[objIndex]['PortalType'].toLowerCase();
          this.apiService.addLocalStorage('domain', this.domain)
          this.handlePortalStyles()
        }
      } else {
        // this.apiService.addLocalStorage('reportData',JSON.stringify(this.reportData));
      }
      if (!this.isSmartPaths) {
        this.getSensorOneColor();
      }else {
        this.helper.getLatestUIVersion('ssmPublic?key=UiVersion');
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    // this.getStopTableData();
  }
  async getSensorOneColor() {
    var url = 'ssm?key=Sensor1Color';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      if (data.value.length > 6) {
        this.Sensor1Color = '#' + data.value.substring(2);
      } else {
        this.Sensor1Color = '#' + data.value;
      }
      console.log(this.Sensor1Color)
      this.getSensorTwoColor();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getSensorTwoColor() {
    var url = 'ssm?key=Sensor2Color';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      if (data.value.length > 6) {
        this.Sensor2Color = '#' + data.value.substring(2);
      } else {
        this.Sensor2Color = '#' + data.value;
      }
      console.log(this.Sensor2Color)
      this.getSensorThreeColor();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getSensorThreeColor() {
    var url = 'ssm?key=Sensor3Color';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      if (data.value.length > 6) {
        this.Sensor3Color = '#' + data.value.substring(2);
      } else {
        this.Sensor3Color = '#' + data.value;
      }
      this.getSensorFourColor();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getSensorFourColor() {
    var url = 'ssm?key=Sensor4Color';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      if (data.value.length > 6) {
        this.Sensor4Color = '#' + data.value.substring(2);
      } else {
        this.Sensor4Color = '#' + data.value;
      }
      console.log(this.Sensor2Color)
      this.getBothSensorColor();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getBothSensorColor() {
    var url = 'ssm?key=BothSensorsColor';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      if (data.value.length > 6) {
        this.BothSensorsColor = '#' + data.value.substring(2);
      } else {
        this.BothSensorsColor = '#' + data.value;
      }
      console.log(this.BothSensorsColor)
      this.getRouteStreetColor();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getRouteStreetColor() {
    var url = 'ssm?key=RouteStreetColor';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.helper.getLatestUIVersion('ssmPublic?key=UiVersion');
      if (data.value) {
        this.routeStreetColor = '#' + data.value;
      }
      const currentDate = new Date();

      // Get the month (0-indexed) and year
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();

      // Calculate the number of days in the month
      const numberOfDays = new Date(year, month, 0).getDate();
      var startDate = year + '-' + month + '-' + '01'
      var endDate = year + '-' + month + '-' + numberOfDays
      this.getCalanderDates(startDate, endDate)
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async cancelReport() {
    var url = 'privateReport?cancel=true&reportReqId=' + this.privatemapData['Id'];
    if (this.isSmartPaths) {
      url = 'smartPathGridGeojson?cancel=true&reportReqId=' + this.gridReportRequestId;
    }
    this.apiService.getByUrl(url).subscribe((data: any) => {
      console.log(data);
      this.isNowStopColorChange = false;
      this.reportData.IsNow = false;
      this.isNowStopColor = this.isNowStopColorChange ? "green" : colorConfig[this.domain]['reports']['nowBg']
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    this.searchLoading = false;
    if (this.id) {
      clearInterval(this.id)
    }
  }
  async getStopTableData() {
    var url = 'routeComplete?orgId=' + this.orgId + '&portalId=' + this.selectedPortal + '&userId=&reportId=' + this.privateMapSettings['reportId'];
    this.apiService.getByUrl(url, true).subscribe((data: any) => {
      if (data.length > 0) {
        data = data.filter(function (obj) {
          return obj.zones !== 'Any';
        });
      }
      this.routeCompleted = new MatTableDataSource(data);
      this.tableData = data;
      // this.sortedData = this.routeCompleted.slice();
      // this.routeCompleted.sort = this.sort;
      if (this.privateMapSettings.reportUrl) {
        this.apiService.isPendingResponse = false;
        this.apiService.hideLoader();
      }
      if (this.tableData.length > 0) {
        this.routeCols = this.routeCols1;
        this.showRouteCompleted = true;
        console.log(this.routeCols)
      }
      console.log("salt view " + this.showSaltView)
      console.log("arrow view " + this.showArrowView)
      console.log("zoom is " + this.zoom)
    }, (error) => {
      console.log(error);
      this.apiService.hideLoader()
      this.helper.openSnackBar(error, "OK")
    })
  }
  getClusterDetails(cluster: ClusterManager): void {
    // cluster.markers_.map(m => { console.log(m); }); 
    console.log(cluster)
  }
  getDesc(getText, lastUpdatedDate) {
    var newstr = this.helper.changeTimeZone(getText, lastUpdatedDate, this.timeZome);

    return newstr
  }
  getTruckDesc(getText) {
    var newstr = this.helper.changeTimeZoneWithTime(getText, this.timeZome);

    return newstr
  }
  openStopageData(getTitle) {
    this.viewStoppageDetails(getTitle);
  }
  viewStoppageDetails(getTitle) {
    var title = 'Stoppage Report';

    const modalData = {};
    modalData['selectedIconTitle'] = getTitle;
    modalData['stoppageRecords'] = this.stoppagePopupData;
    modalData['timeZone'] = this.timeZome;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        data: modalData,
        orgId: this.orgId,
        type: 'viewStoppage'
      },
      minWidth: 80
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
    });
  }
  saveSmartpathGrid() {
    var title = 'Add new grid';

    const modalData = {};
    modalData['polygonCoordinates'] = this.polygonPaths;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        data: modalData,
        orgId: this.orgId,
        orgName: this.orgName,
        userEmail: this.userEmail,
        portalId: this.selectedPortal,
        type: 'addGrid'
      },
      minWidth: 80
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
    });
  }
  isNowCheck(field) {
    if (field === "start") {
      this.StartTime = new Date();
      this.isNowStartColorChange = !this.isNowStartColorChange;
      this.isNowStartColor = this.isNowStartColorChange ? "green" : "#c1c1c1"
    }
    if (field === "stop" && !this.searchLoading) {
      this.StopTime = new Date();
      this.isNowStopColorChange = !this.isNowStopColorChange;
      this.reportData.IsNow = this.isNowStopColorChange === true ? true : false;
      this.isNowStopColor = this.isNowStopColorChange ? "green" : colorConfig[this.domain]['reports']['nowBg']
    }
  }
  initSearch() {
    if (this.id) {
      clearInterval(this.id);
    }
    this.privateMapSettings = {};
    this.allSaltPositions = [];
    this.showArrow = false;
    this.isReportGenerated = false;
    this.showtruckPoints = false;
    this.showArrowView = false;
    this.showSaltView = false;
    this.showSaltMarkerView = false
    this.showSmartpathView = false;
    this.showReportKmz = false;
    if ((this.StartTime != null && this.StopTime != null) && (this.StopTime) < (this.StartTime)) {
      // this.error={isError:true,errorMessage:'End date should be grater then start date.'};
      // this.isValidDate = false;
      this.showWarning("Stop date should be greater then start date.");
    } else if (this.reportData['TruckId'].length === 0) {
      this.showWarning("Any one vehicle is required.");
    } else if ((this.reportData['RouteId'] && this.reportData['RouteId'].length === 0) ||
      (this.reportData['polygonGridId'] && this.reportData['polygonGridId'].length === 0)) {
      this.showWarning("Any one zone is required.");
    } else {

      var dateFuture: any = new Date(this.StopTime);
      var dateNow: any = new Date(this.StartTime);

      var seconds = Math.floor((dateFuture - (dateNow)) / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      hours = hours - (days * 24);
      minutes = minutes - (days * 24 * 60) - (hours * 60);
      seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
      console.log('days ' + days + ' hours ' + hours + ' minutes ' + minutes + ' seconds ' + seconds);
      if (days < this.MaxReportDays || (days === this.MaxReportDays && hours === 0 && minutes === 0 && seconds === 0)) {
        if (this.isSmartPaths) {
          this.getSmartpathData();
          this.id = setInterval(() => {
            if (this.reportData.IsNow) {
              this.StopTime = new Date();
              this.isNowTriggered = true;
              this.getSmartpathData();
              console.log(this.id)
            }
          }, 60000);
        } else {
          // if (this.reportData['TruckId'].length > 1) {
          //   this.getAllPositions();
          //   this.id = setInterval(() => {
          //     if (this.reportData.IsNow) {
          //       this.StopTime = new Date();
          //       this.isNowTriggered = true;
          //       this.getAllPositions();
          //       console.log(this.id)
          //     }
          //   }, 60000);
          // } else {
            this.getSearch();
            this.id = setInterval(() => {
              if (this.reportData.IsNow) {
                this.StopTime = new Date();
                this.isNowTriggered = true;
                this.getSearch();
                console.log(this.id)
              }
            }, 60000);
          // }
        }
      } else {
        this.showWarning("Timespan must be " + this.MaxReportDays + " day(s) or less");
      }
    }
  }
  checkDisableGrid() {
    if (this.gridObj && this.gridObj['Id']) {
      return true
    } else {
      return false
    }
  }
  async getSmartpathData() {
    this.showGridReport = false;;
    this.smartPathData = {}
    this.smartPathGridGeojsonPayload = {};
    this.updatedGeoJsonObject = {};
    this.apiService.isPendingResponse = true;
    this.searchLoading = true;
    this.smartGridReportType = '';
    // this.showMap = false;
    var payload = Object.assign({}, this.reportData);
    payload['StartTime'] = this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000')
    payload['StopTime'] = this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000')
    console.log(this.reportData);
    if (payload['Trucktype'] === 'all') { payload['Trucktype'] = 0 }
    payload['TruckId'] = [];
    this.reportData['TruckId'].map(truck => {
      payload['TruckId'].push(truck.Id)
    })
    if (payload['TruckId'].includes('all')) {
      payload['TruckId'] = [];
      payload['TruckId'].push(0);
      // payload['TruckId'].push(0)
    } else {
      payload['TruckId'] = [];
      this.reportData['TruckId'].map(truck => {
        payload['TruckId'].push(truck.Id)
      })
    }
    delete payload['RouteId'];
    delete payload['IsNow'];
    delete payload['ShowSensor1'];
    delete payload['ShowSensor2'];
    delete payload['ShowSensor3'];
    delete payload['ShowSensor4'];
    if (this.isSmartPaths) {
      payload['PolygonGridId'] = this.reportData['polygonGridId'];
      if (payload['PolygonGridId'].includes('all')) {
        payload['PolygonGridId'] = [];
        payload['PolygonGridId'].push(0);
      }
    }
    delete payload['polygonGridId'];
    var url = 'smartPathGridGeojson'
    this.smartPathGridGeojsonPayload = payload;
    this.apiService.postNewReportdata(url, payload, false).subscribe(
      (res: any) => {
        console.log(res);
        this.updatedGeoJsonObject = res['geojson'];
        res['geojson'].map((url: any) => {
          if (url !== '' && (this.smartGridReportType === '' || this.smartGridReportType === undefined)) {
            this.smartGridReportType = url.split('.').pop();
          }
        });
        console.log(this.smartGridReportType);
        this.gridTrucks = res['gridInfo'];
        this.gridReportRequestId = res['gridReportRequestId'];
        if (this.gridTrucks[0]) {
          this.lat = this.gridTrucks[0]['latitude'];
          this.lng = this.gridTrucks[0]['longitude'];
        }
        // this.zoom = 16;
        this.showSmartpathView = true;
        this.showgridTrucks = true;
        this.showGridReport = true;
        var allTruckIds = [];
        this.reportData['TruckId'].map(truck => {
          allTruckIds.push(truck.Id);
        });
        if (allTruckIds.includes('all') || allTruckIds.includes(0)) {
          this.getGridZoneTableData();
        } else {
          setTimeout(() => {
            this.getAllPositions();
          }, 2000);
        }
        this.showMap = true;
        setTimeout(() => {
          if (!this.reportData.IsNow) {
            this.searchLoading = false;
          }
        }, 4000);
      }, (error) => {
        console.log(error);
        this.searchLoading = false;
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK");
      }
    )
  }
  async getGridZoneTableData() {
    var url = 'smartPathGridCompleteReport';
    var payload = {
      OrgId: Number(this.orgId),
      PortalId: this.selectedPortal,
      GridReportRequestId: this.gridReportRequestId
    }
    this.apiService.postNewdata(url, payload).subscribe((data: any) => {
      console.log(data);
      if (data.length > 0) {
        data = data.filter(function (obj) {
          return obj.zones !== 'Any';
        });
      }
      this.routeCompleted = new MatTableDataSource(data);
      this.tableData = data;
      // this.sortedData = this.routeCompleted.slice();
      // this.routeCompleted.sort = this.sort;
      if (this.tableData.length > 0) {
        this.routeCols = this.gridTableCols;
        this.showRouteCompleted = true;
        console.log(this.routeCols)
      }
    }, (error) => {
      console.log(error);
      this.apiService.hideLoader()
      this.helper.openSnackBar(error, "OK")
    })
  }
  zoomChange(event: any) {
    this.zoom = event;
    console.log(this.zoom)
    this.apiService.addLocalStorage('mapZoom', this.zoom)
  }

  showWarning(errMsg) {
    // var errMsg = "Timespan must be " + this.MaxReportDays + " day(s) or less";
    this.messageService.add({ key: 'bl', severity: 'error', summary: errMsg, detail: '' });
  }
  async getDataChange(getText) {
    // if(col === 'startTime' || col === 'endTime'){
    const uTCDatetime = mom.tz(getText, "UTC").format();
    const to = mom.tz(uTCDatetime, this.timeZome).format("MM/DD/yyyy hh:mm:ss A");
    var regExp = new RegExp(getText, 'gi');
    var newstr = getText.replace(regExp, to);
    return newstr
    // }else {
    // return getText
    // }
  }
  getChanged(getType) {
    this.customView = getType;
    console.log(this.defaultView);
    this.defaultView = getType;
    if (getType === "arrow") {
      this.showArrowView = true;
      this.showSaltView = false;
      this.showSaltMarkerView = false;
    } else if (getType === "salter") {
      this.showArrowView = false;
      this.showSaltView = false;
      this.showSaltMarkerView = false;
      this.getSalterData();
    } else if (getType === "salterMarkerGranular" || getType === "salterMarkerLiquid") {
      this.showArrowView = false;
      this.showSaltView = false;
      this.showSaltMarkerView = false;
      this.getSalterData();
    } else {
      this.showArrowView = false;
      this.showSaltView = false;
      this.showSaltMarkerView = false;
    }
    this.showtruckPoints = this.showArrowView;
  }
  getChangedZoneByStreettype(zoneByStreettype){
    if(zoneByStreettype === 'createZoneByStreet' && this.gridObj.Id){
      this.gridObj.name = '';
      delete this.gridObj.Id;
    }
    if(zoneByStreettype === 'addStreets'){
      this.selectedAddStreetRoute = '';
      this.selectedZoneGJsonData = {};
    }
  }
  checkSearch() {
    var ret = true;
    // console.log(this.reportData)
    if (!this.reportData['Trucktype'] || this.isSmartPaths ? !this.reportData['polygonGridId'] : !this.reportData['RouteId'] || !this.reportData['TruckId']) {
      ret = true
    } else {
      ret = false
    }
    return ret;
  }
  checkTransportSearch() {
    var ret = true;
    // console.log(this.reportData)
    if (!this.reportData['Trucktype'] || !this.reportData['RouteId'] || !this.reportData['TruckId']) {
      ret = true
    } else {
      ret = false
    }
    return ret;
  }

  getPrintValue(value, field) {
    if (!this.checkSearch() && value) {
      if (field === 'portal') {
        var selectedObj = this.allPortals.find(obj => {
          return obj.Id === value;
        })
        return (selectedObj && selectedObj['Name']) ? selectedObj['Name'] : ''
      }
      if (field === 'Trucktype') {
        var selectedObj = this.trucksTypesArray.find(obj => {
          return obj.Id === value;
        })
        return (selectedObj && selectedObj['Name']) ? selectedObj['Name'] : ''
      }
      if (field === 'RouteId') {
        if (value.length > 0) {
          var selectedObj: any = "";
          value.map(obj => {
            selectedObj += obj.Name;
          })
        } else {
          selectedObj = value;
        }
        return selectedObj ? selectedObj : ''
      }
      if (field === 'TruckId') {
        if (value.length > 0) {
          var selectedObj = this.trucksArray.find(obj => {
            return obj.Id === value[0].Id;
          })
        } else {
          selectedObj = this.trucksArray[0];
        }
        return (selectedObj && selectedObj['Description']) ? selectedObj['Description'] : ''
      }
      if (field === 'polygonGridId') {
        if (value.length > 0) {
          var selectedObj = this.gridListDropdownList.find(obj => {
            return value === 'all' ? obj.Id === value : obj.Id === value[0];
          })
        } else {
          selectedObj = this.gridListDropdownList[0];
        }
        return (selectedObj && selectedObj['Name']) ? selectedObj['Name'] : '';
      }
    }
  }
  dateChange(getStartDate) {
    console.log(getStartDate)
    this.StopTime = new Date(this.StartTime)
    this.StopTime.setMinutes(0);
    this.StopTime.setSeconds(0);
    // this.StartTime.setMinutes(0);
    // this.StartTime.setSeconds(0);
    var dat = this.StartTime.getDate();
    var tim = this.StartTime.getHours();
    this.StopTime.setDate(this.StartTime.getDate() + 1);
    this.StopTime.setHours(this.StartTime.getHours());
  }
  printMap() {
    // window.open(this.urlOrigin, '_self', '');
    // window.close();
    this.apiService.addLocalStorage('startTime', JSON.stringify(this.StartTime))
    this.apiService.addLocalStorage('stopTime', JSON.stringify(this.StopTime))
    if(!this.isMaster){
      this.apiService.addLocalStorage('reportData', JSON.stringify(this.reportData))
    }

    var originalContents = document.body.innerHTML;
    document.getElementsByClassName("header")[0]['style'].display = 'none';
    document.getElementsByClassName("footer")[0]['style'].display = 'none';
    document.getElementsByClassName("zsiq_floatmain zsiq_theme11 siq_bR")[0]['style'].display = 'none';
    document.getElementsByClassName(this.formClass)[0]['style'].display = 'none';
    document.getElementsByClassName(this.endClass)[0]['style'].display = 'none';

    document.body['style'].height = '95%';
    document.getElementsByClassName("main-container")[0]['style'].display = 'inline-block';
    document.getElementsByClassName("main-container")[0]['style'].height = '800px';
    document.getElementsByClassName(this.mapClass)[0]['style'].width = '90%';
    document.getElementsByClassName(this.mapClass)[0]['style'].height = '600px';
    document.getElementsByClassName('agm-map')[0]['style'].height = '600px';
    window.print();
    this.changeDetectorRef.detectChanges();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }
  printSmartMap() {
    this.apiService.addLocalStorage('startTime', JSON.stringify(this.StartTime))
    this.apiService.addLocalStorage('stopTime', JSON.stringify(this.StopTime))
    if(!this.isMaster){
      this.apiService.addLocalStorage('reportData', JSON.stringify(this.reportData))
    }
    var originalContents = document.body.innerHTML;
    document.getElementsByClassName("header")[0]['style'].display = 'none';
    document.getElementsByClassName("footer")[0]['style'].display = 'none';
    document.getElementsByClassName("zsiq_floatmain zsiq_theme11 siq_bR")[0]['style'].display = 'none';
    document.getElementsByClassName(this.formClass)[0]['style'].display = 'none';
    document.getElementsByClassName(this.endClass)[0]['style'].display = 'none';

    document.body['style'].height = '95%';
    document.getElementsByClassName("main-container")[0]['style'].display = 'inline-block';
    document.getElementsByClassName("main-container")[0]['style'].height = '800px';
    document.getElementsByClassName(this.mapClass)[0]['style'].width = '90%';
    document.getElementsByClassName(this.mapClass)[0]['style'].height = '600px';
    document.getElementsByClassName('agm-map')[0]['style'].height = '600px';
    window.print();
    this.changeDetectorRef.detectChanges();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }
  changeHeader(col) {
    var header = col === 'completedDistance' ? "completed" : col === 'remainingDistance' ? "remaining" : col;
    return header;
  }
  getSvg(getSvg) {
    var svgData = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg);
    return svgData;
  }
  getSalterSvg(getSalter, markerData,pIndex, cIndex) {
    var svgIcon = '';
    if(markerData.showBubble === 0){
      svgIcon = markerData.emptyBubble
      var svgData = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgIcon);
    }else {
      svgIcon = getSalter.clrBubble;
      if (getSalter.emptyBubble) {
        svgIcon = getSalter.emptyBubble
      }
      var svgData = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgIcon);
    }
    return svgData;
  }

  showLegends() {
    var title = 'Salter data color ranges';

    const modalData = {};
    modalData['salterLegend'] = this.salterDataColorRange;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        data: modalData,
        orgId: this.orgId,
        type: 'viewSalterLegend'
      },
      minWidth: 80
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
    });
  }
  activeChange(ob: MatCheckboxChange) {
    console.log(ob.checked);
    this.zeroSalt = ob.checked;
    this.getZeroSalterData();
  }
  gridActiveChange(ob: MatCheckboxChange) {
    console.log(ob.checked);
    this.isIncludeInactiveZones = ob.checked;
    this.isInactiveZones = !this.isIncludeInactiveZones;
    if (this.isIncludeInactiveZones) {
      this.filteredGridList = this.allGridList;
    }
    if (!this.isIncludeInactiveZones) {
      this.filteredGridList = this.activeGridList;
    }
    if (this.filteredGridList.length > 0) {
      this.gridListDropdownList = this.allTType.concat(this.filteredGridList);
    } else {
      this.gridListDropdownList = this.allTType;
    }
  }

  onTruckSelect(selectedTruck: any) {
    console.log(selectedTruck);
    this.salterAvailable = false;
    if (selectedTruck.Description === "all") {
      this.reportData['TruckId'] = []
      this.reportData['TruckId'].push(selectedTruck)
    } else {
      this.reportData['TruckId'] = this.reportData['TruckId'].filter(truck => {
        return truck.Description !== "all"
      })
    }
    
    if (this.OrgDetails['organization'].ReportTruckLimit !== 0 && this.reportData['TruckId'].length >= this.OrgDetails['organization'].ReportTruckLimit+1) {
      this.reportData['TruckId'].shift();
    }
  }
  onSmartZoneSelect(key: string, selectedZone: any) {

    if (selectedZone === "all") {
      this.reportData[key] = [];
      this.reportData[key].push(selectedZone)
    } else {
      this.reportData[key] = [];
      this.reportData[key].push(Number(selectedZone))
      var selectedZone = this.gridListDropdownList.filter(zone => {
        return zone.Id === Number(selectedZone)
      })
      console.log(selectedZone);
      if (selectedZone[0]) {
        this.lat = selectedZone[0]['PolygonCoordinates'][0]['lat'];
        this.lng = selectedZone[0]['PolygonCoordinates'][0]['lng'];
        this.zoom = 16;
      }
    }
    if (!this.isSmartPaths) {
      this.apiService.removeLocalStorage('reportData')
      // this.routesArray.map(route => {
      //   if(route.Id === this.reportData['RouteId'][0]['Id']){
      //     if(route.TruckTypeId){
      //       var truckTypeObj = this.trucksTypesArray.find(tType => {
      //         return tType.Id === route.TruckTypeId
      //       })
      //       this.reportData['Trucktype']=truckTypeObj.Name.toLowerCase() !== 'any'?route.TruckTypeId:"all";
      //     }else {
      //       this.reportData['Trucktype']="all";
      //     }
      //   }
      // })
      console.log(this.trucksTypesArray);
      this.getTrucks()
    }
  }
  onZoneSelect(key: string, selectedZone: any) {

    if (selectedZone.Name === "all") {
      this.reportData[key] = []
      this.reportData[key].push(selectedZone)
    } else {
      this.reportData[key] = this.reportData[key].filter(obj => {
        return obj.Name !== "all"
      })

    }
    if (!this.isSmartPaths) {
      this.apiService.removeLocalStorage('reportData')
      console.log(this.trucksTypesArray);
      this.getTrucks()
    }
  }
  onTransRouteSelect(key: string, selectedZone: any) {

    if (selectedZone.RouteName === "all") {
      this.reportData[key] = []
      this.reportData[key].push(selectedZone)
    } else {
      this.reportData[key] = this.reportData[key].filter(obj => {
        return obj.RouteName !== "all"
      })

    }
  }
  openReport() {
    window.open('Admin/Reports', '_blank');
  }
  getRectangleBounds(event: any) {
    console.log(event)
  }
  styleFunc(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const fillcolor = feature.getProperty('fill_color');
    const opacity = feature.getProperty('opacity');
    return {
      clickable: true,
      strokeColor: strokeColor,
      strokeWeight: 1,
      fillColor: fillcolor,
      fillOpacity: opacity,
      zIndex: 9999,
    };
  }
  onMapClick(event: MouseEvent) {
    if (this.reportScreenType !== '' && this.reportScreenType === 'grid') {
      // const path = this.polygonPaths.slice();
      // path.push({
      //   lat: event.coords.lat,
      //   lng: event.coords.lng
      // });
      // this.polygonPaths = path;
      // console.log(this.polygonPaths);
    } else {
      this.polygonPaths = [];
    }
  }
  undoGrid(event: MouseEvent) {
    this.polygonPaths.pop();
    this.onMapClick(event)
  }
  resetGrid() {
    this.polygonPaths = []
    this.polygon.setPaths([]);
    this.showDrawActions = false;
  }
  // onMapReady(map: any) {
  //   const clearPolygonControlDiv = document.createElement('div');
  //   const clearPolygonControl = this.createClearPolygonControl(clearPolygonControlDiv);
  //   map.mapTypes.set('clearPolygonControl', clearPolygonControl);
  //   map.setMapTypeId('clearPolygonControl');
  // }
  createClearPolygonControl(controlDiv: HTMLElement): HTMLElement {
    const clearPolygonButton = document.createElement('button');
    clearPolygonButton.innerHTML = 'Clear Polygon';
    clearPolygonButton.addEventListener('click', () => {
      this.clearPolygon();
    });
    controlDiv.appendChild(clearPolygonButton);
    return controlDiv;
  }

  clearPolygon() {
    if (this.polygonPaths.length) {
      this.polygonPaths = [];
    }
  }
  async postNewPolygonData() {

    var url = this.reportScreenType === 'grid' ? 'smartPathPolygonGrid' : 'RouteNonGis';
    this.gridObj.orgId = Number(this.orgId);
    this.gridObj.orgName = this.orgName;
    this.gridObj.portalId = this.selectedPortal;
    this.gridObj.userEmail = this.userEmail;
    if (this.reportScreenType === 'grid') {
      var paths = await this.getPaths();
      this.gridObj.polygonCoordinates = paths;
    }
    if (this.reportScreenType === 'street') {
      var paths = await this.getPaths();
      this.gridObj.coordinates = paths;
    }
    if (this.gridObj.name !== '' && this.gridObj.gridSize !== 0) {
      this.apiService.postNewdata(url, this.gridObj).subscribe(
        (res: any) => {
          console.log(res)
          this.helper.openSnackBar("Successfully created zone " + this.gridObj.name + ".", "OK");
          this.polygonPaths = [];
          this.gridObj.name = '';
          if (this.reportScreenType === 'grid') {
            this.getSmartpathPolygonData();
          }
          if (this.reportScreenType === 'street') {
            this.polygon.setPaths([]);
            this.showDrawActions = false;
            this.getRoutesPolygonData();
          }
          this.resetGrid()
        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
    } else {
      this.helper.openSnackBar("Zone all inputs mandatory!", "OK")
    }
  }
  formatLabel(value: number) {
    return value;
  }
  addGridValue(key: any, value: any) {
    if (key === 'opacity' || key === 'gridSize') {
      value = Number(value);
    }
    this.gridObj[key] = value;
  }
  onChangeGridColor(key: string, event: ColorEvent) {
    this.gridObj[key] = event.color.hex;
  }
  updateGridZone(selectedZone: any) {
    this.gridObj['Id'] = selectedZone.Id;
    this.gridObj.name = selectedZone.Name;
    this.gridObj.opacity = selectedZone.Opacity;
    this.gridObj.gridSize = selectedZone.GridSize;
    this.gridObj.fillColor = selectedZone.FillColor;
    this.gridObj.strokeColor = selectedZone.StrokeColor;
    this.polygonPaths = selectedZone.PolygonCoordinates;
    this.reportScreenType = "grid";
    this.infoWindow.close();
  }
  async saveUpdateGridZone() {
    var payload = {};
    var url = '';
    if (this.reportScreenType === 'street') {
      url = 'RouteNonGis?routeId=' + this.gridObj['Id'];
      payload = {
        FillColor: this.gridObj.fillColor,
        TruckTypeId: this.gridObj['truckTypeId']
      }
    }
    if (this.reportScreenType === 'grid') {
      var url = 'smartPathPolygonGrid?polygonId=' + this.gridObj['Id'];
      payload = {
        PolygonFillColor: this.gridObj.polygonFillColor,
        CellFillColor: this.gridObj.cellFillColor,
        Opacity: this.gridObj.opacity
      }
    }
    await this.apiService.updatedata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar(this.gridObj.name + " zone updated successfully!", "OK")
        this.polygonPaths = [];
        this.reportScreenType === 'street' ? this.getRoutesPolygonData() : this.getSmartpathPolygonData();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async deleteGridZone(zone) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Please confirm that you\'d like to delete this zone:?: \n\n ' + zone['Name']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.delAlert(zone)
      }
    });
  }
  async confirmDelete(route) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Please confirm that you\'d like to delete this route:?: \n\n ' + route['RouteName']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.delRoute(route)
      }
    });
  }
  async delRoute(route) {
    var url = 'transport?orgId=' + this.orgId + '&portalId=' + this.selectedPortal + '&transportRouteId=' + route.Id;
    await this.apiService.deleteData(url).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar(route.RouteName + " route deleted successfully!", "OK")
        this.getTransportRoutes();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async delAlert(zone) {
    var url = this.reportScreenType === 'grid' ? 'smartPathPolygonGrid?polygonId=' + zone['Id'] : 'RouteNonGis?routeId=' + zone['Id'] + '&orgId=' + this.orgId + '&portalId=' + this.selectedPortal
    await this.apiService.deleteData(url).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar(zone.Name + " zone deleted successfully!", "OK")
        if (this.reportScreenType === 'grid') {
          this.getSmartpathPolygonData();
          this.infoWindow.close();
        }
        if (this.reportScreenType === 'street') {
          this.getRoutesPolygonData();
          this.zoneInfoWindow.close();
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getUpdatedPalygonPaths = (event: any) => {
    this.polygonPaths = [];
    console.log(this.polygonPaths)
    this.polygonPaths = event.newArr[0];
  }
  async onPolyMouseOver(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    console.log(position)
    this.selectedGridPoint = position;
    console.log("Selected grid points is " + this.selectedGridPoint);
    console.log(this.polygonPaths)

  }
  async getUpdatedPalygonPaths1(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    console.log(position)
  }
  polyDragEvent(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    console.log(position)
  }
  polyDblClickEvent(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    console.log(position)
  }
  polyDragStart(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    console.log(position)
  }
  polyMouseDownEvent(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    console.log(position)
  }
  polyMouseMoveEvent(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    // console.log(position)
  }
  polyMouseOutEvent(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    // console.log(position)
  }
  polyMouseUpEvent(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    console.log("Selected grid point is " + this.selectedGridPoint)
    console.log("new point is " + position)
    console.log("drag Points replace/add initiated.")
    if (event.vertex) {
      var updatedArray = [];
      updatedArray = this.findAndReplaceOrAddObject(this.polygonPaths, this.selectedGridPoint, position);
      this.polygonPaths = Object.assign([], updatedArray)
    }
    if (event.edge) {
      this.polygonPaths.splice(event.edge + 1, 0, position);
    }
    console.log("updated polygon paths " + this.polygonPaths);
  }
  polyRightClickEvent(event: any) {
    var position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    console.log(position)
  }
  findAndReplaceOrAddObject(dataArray: any[], objectToFind: any, objectToAdd: any): any[] {
    const foundIndex = dataArray.findIndex(item => item.lat === objectToFind.lat && item.lng === objectToFind.lng);

    if (foundIndex !== -1) {
      // Replace existing object
      dataArray[foundIndex] = objectToAdd;
    } else {
      // Add new object
      dataArray.push(objectToAdd);
    }

    return dataArray;
  }
  getDomain() {
    var selectedPortalObj = this.allPortals.find(obj => {
      return obj.Id === this.selectedPortal;
    })
    if (selectedPortalObj && selectedPortalObj.Id !== 0) {
      this.bgColor = 'white';
      this.domain = selectedPortalObj['PortalType'].toLowerCase();
      if (this.domain) {
        this.apiService.domain = this.domain;
        this.handlePortalStyles();
      }
    }
  }
  handleActiveChange(gridObj: any, event: any) {
    var url = 'smartPathPolygonGrid?polygonId=' + gridObj['Id'];
    var payload = {
      Active: this.activateChecked ? 1 : 0
    }
    this.apiService.updatedata(url, payload).subscribe((data: any) => {
      console.log(data)
      this.helper.openSnackBar(this.gridObj.name + " zone " + (this.activateChecked ? "Activated" : "Deactivated") + " successfully!", "OK")
      this.getSmartpathPolygonData();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  updateLastVisitedPortal() {
    // var username = this.apiService.getLocalStorage('username');
    var username = this.tokenValue['cognito:username']
    var url = 'user?username=' + username.toLowerCase();
    var payload = {
      lastVisitedPortal: this.selectedPortal
    }
    this.apiService.updatedata(url, payload).subscribe((data: any) => {
      console.log(data)
    }, (error) => {
      console.log(error);
    })
  }
  // NonGisPrivateReport
  getprivateReportGeojson() {
    var url = 'ORSPrivateReport';
    var payload = Object.assign({}, this.reportData);
    payload['StartTime'] = this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000')
    payload['StopTime'] = this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000')
    console.log(this.reportData);
    if (payload['Trucktype'] === 'all') { payload['Trucktype'] = 0 }
    // payload['TruckId'] = this.reportData['TruckId'][0]['Id'] === 'all'?0:this.reportData['TruckId'][0]['Id'];
    payload['TruckId'] = [];
    this.reportData['TruckId'].map(truck => {
      payload['TruckId'].push(truck.Id)
    })
    if (payload['TruckId'].includes('all')) {
      payload['TruckId'] = [];
      // payload['TruckId']=0;
      payload['TruckId'].push(0)
    } else {
      payload['TruckId'] = [];
      this.reportData['TruckId'].map(truck => {
        payload['TruckId'].push(truck.Id)
      })
    }
    var routeIds = []
    this.reportData['RouteId'].map(truck => {
      routeIds.push(truck.Id)
    })
    if (routeIds.includes('all')) {
      payload['RouteId'] = null;
    } else {
      payload['RouteId'] = routeIds
    }
    this.geoPayload = payload;

    this.apiService.postNewdata(url, this.geoPayload).subscribe((data: any) => {
      console.log(data)
      this.routeGeojson = data.geojsonUrls;
      this.getStopTableData();
      this.showMap = true;
      this.searchLoading = false;
    }, (error) => {
      console.log(error);
      this.searchLoading = false;
    })
  }
  lineStringStyle(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');
    const strokeOpacity = feature.getProperty('strokeOpacity');

    // #b99885 brown
    return {
      strokeColor: strokeColor ? strokeColor : '#1913E6',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: 5,
      strokeOpacity: strokeOpacity ? strokeOpacity : 0.4
    };
  }
  zonelineStringStyle(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');
    const strokeOpacity = feature.getProperty('strokeOpacity');

    // #b99885 brown
    return {
      strokeColor: '#b99885',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: 5,
      zIndex: 9999,
      strokeOpacity: 1
    };
  }
  priorityLineStringStyle(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');
    const strokeOpacity = feature.getProperty('strokeOpacity');

    // #b99885 brown
    return {
      strokeColor: strokeColor ? strokeColor : '#1913E6',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: 5,
      zIndex: 0,
      strokeOpacity: strokeOpacity ? strokeOpacity : 0.4
    };
  }
  privateReportlineStringStyle(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');
    const strokeOpacity = feature.getProperty('strokeOpacity');

    // #1913E6 blue
    return {
      strokeColor: strokeColor ? strokeColor : '#1913E6',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: 5,
      zIndex: 9999,
      strokeOpacity: strokeOpacity ? strokeOpacity : 0.4
    };
  }
  boundarylineStringStyle(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');

    // #b99885 brown
    return {
      strokeColor: strokeColor ? strokeColor : 'red',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: 2,
    };
  }
  routeLineStringStyle(feature: any) {
    // #b99885 brown
    const strokeColor = feature.getProperty('stroke');
    const strokeWeight = feature.getProperty('strokeWeight');
    const strokeOpacity = feature.getProperty('strokeOpacity');
    return {
      strokeColor: strokeColor ? strokeColor : '#b99885',
      strokeWeight: strokeWeight ? strokeWeight : 5,
      strokeOpacity: strokeOpacity ? strokeOpacity : 0.4
    };
  }
  dalaLayerClicked(event: MouseEvent) {
    this.apiService.showLoader();
    if (this.selectedgJsonStreetData && this.selectedgJsonStreetData.streetName) {
      this.geoJsonInfoWindow.close();
    }
    console.log(event)
    this.clickedLatLng = {
      lat: event['latLng'].lat(),
      lng: event['latLng'].lng()
    };
    console.log(this.clickedLatLng.lat + ',' + this.clickedLatLng.lng)
    this.selectedgJsonStreetData = {};
    var url = 'https://nominatim.openstreetmap.org/reverse?lat=' + this.clickedLatLng.lat + '&lon=' + this.clickedLatLng.lng + '&format=json';
    this.apiService.getByExternalUrl(url).subscribe((data: any) => {
      console.log(data);
      if (data.address) {
        this.selectedgJsonStreetData.streetName = data.address.house_number ? data.address.house_number + ', ' + data.address.road + '<br>' : '' + data.address.road + '<br>'
      }
      var streetName = event['feature'].getProperty('name') ? event['feature'].getProperty('name') : '';
      var highway = event['feature'].getProperty('highway');
      var length = event['feature'].getProperty('length');
      var zoneName = event['feature'].getProperty('zoneName');
      if (streetName && streetName !== '') {
        if (!zoneName) {
          this.selectedgJsonStreetData.streetName += streetName
        }
        if (zoneName) {
          this.selectedgJsonStreetData.streetName = streetName
        }
      }
      // var description = event['feature'].getProperty('description');


      if (highway && highway !== '') {
        this.selectedgJsonStreetData.highway = highway
      }
      if (length && length !== '') {
        this.selectedgJsonStreetData.length = length
      }
      if (zoneName && zoneName !== '') {
        this.selectedgJsonStreetData.zoneName = zoneName
      }

      if (this.selectedgJsonStreetData.streetName) {
        this.changeDetectorRef.detectChanges();
        // Open the info window or perform any other actions with the latLng
        if (!this.geoJsonInfoWindow.isOpen || this.geoJsonInfoWindow.isOpen) {
          this.geoJsonInfoWindow.open()
        }
        this.geoJsonInfoWindow.open();
      }
      this.apiService.hideLoader();
    }, (error) => {
      console.log(error);
      this.apiService.hideLoader();
      this.searchLoading = false;
    })
    // var streetName = event['feature'].getProperty('name')?event['feature'].getProperty('name'):'';
  }
  onGridDataLayerClicked(event: MouseEvent) {
    this.apiService.showLoader();
    if (this.selectedGeojsonSmartGrid && this.selectedGeojsonSmartGrid.description) {
      this.gridGeoJsonInfoWindow.close();
    }
    if (this.infoWindow && (!this.infoWindow.isOpen || this.infoWindow.isOpen)) {
      this.infoWindow.close()
    }
    console.log(event)
    this.clickedLatLng = {
      lat: event['latLng'].lat(),
      lng: event['latLng'].lng()
    };
    if(this.smartGridReportType === 'kmz'){
      const iContent = event['featureData'].infoWindowHtml;
      event['featureData'].infoWindowHtml = '';
    }
    var gridId = this.smartGridReportType === 'geojson'?event['feature'].getProperty('gridId'):event['featureData'].name;
    var url = 'smartPathGridGeojson?gridId='+gridId;
    this.apiService.postNewReportdata(url, this.smartPathGridGeojsonPayload, false).subscribe(
      (res: any) => {
        this.selectedGeojsonSmartGrid = {};
        this.selectedGeojsonSmartGrid['description'] = res.res;
        if (this.selectedGeojsonSmartGrid.description) {
          this.changeDetectorRef.detectChanges();
          // Open the info window or perform any other actions with the latLng
          if (!this.gridGeoJsonInfoWindow.isOpen || this.gridGeoJsonInfoWindow.isOpen) {
            this.gridGeoJsonInfoWindow.open()
          }
          this.gridGeoJsonInfoWindow.open();
        }
        this.apiService.hideLoader();
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK");
      }
    )
  }
  getORSStreetDetails() {

  }
  onKmlLayerClick(event: MouseEvent) {
    console.log(event)
  }
  onReportKmlLayerClick(event: any) {
    console.log(event)
    if(event.featureData.name === ''){
      this.apiService.showLoader();
      const lat = event.latLng.lat();
      const lon = event.latLng.lng();
      console.log(lat, lon)
      const iContent = event.featureData.infoWindowHtml;
      event.featureData.infoWindowHtml = '';
  
      this.kmzInfoWindowLat = lat;
      this.kmzInfoWindowLng = lon;
      this.kmzInfoWindowHtml = 'Loading address...';
      
      this.nominatimService.getAddress(lat, lon).subscribe(
        data => {
          var address = data.display_name.split(",")
          this.kmzInfoWindowHtml = `<p style="margin: 5px"><b>${address[0]+','+address[1]+','+address[2]}</b></p><br>`;
          this.kmzInfoWindowHtml += iContent
          this.kmzInfoWindowHtml = this.sanitizer.bypassSecurityTrustHtml(this.kmzInfoWindowHtml);
          this.showkmzInfoWindow = true;
          if (!this.kmzInfoWindow.isOpen || this.kmzInfoWindow.isOpen) {
            this.kmzInfoWindow.open()
          }
          this.kmzInfoWindow.open();
          this.apiService.hideLoader();
        },
        error => {
          console.error('Error fetching address', error);
          // this.kmzInfoWindowHtml = '<p style="margin: 5px">-</p><br>';
          this.kmzInfoWindowHtml = iContent
          this.kmzInfoWindowHtml = this.sanitizer.bypassSecurityTrustHtml(this.kmzInfoWindowHtml);
          this.showkmzInfoWindow = true;
          if (!this.kmzInfoWindow.isOpen || this.kmzInfoWindow.isOpen) {
            this.kmzInfoWindow.open()
          }
          this.kmzInfoWindow.open();
          this.apiService.hideLoader();
        }
      );
    }
  }
  getAssetIcon(getData) {
    var sensorObj = {
      sensor1 : getData.sensor1,
      sensor2 : getData.sensor2,
      sensor3 : getData.sensor3,
      sensor4 : getData.sensor4
    }
    var any2True = this.hasTwoTrueProperties(sensorObj);
    var logo = (getData.sensor1 === true && getData.sensor2 === false && getData.sensor3 === false && getData.sensor4 === false) ? 'sensor1' : 
    (getData.sensor1 === false && getData.sensor2 === true && getData.sensor3 === false && getData.sensor4 === false) ? 'sensor2' : 
    (getData.sensor1 === false && getData.sensor2 === false && getData.sensor3 === true && getData.sensor4 === false) ? 'sensor3' : 
    (getData.sensor1 === false && getData.sensor2 === false && getData.sensor3 === false && getData.sensor4 === true) ? 'sensor4' : 
    (getData.sensor1 === false && getData.sensor2 === false && getData.sensor3 === false && getData.sensor4 === false) ? this.domain + 'teal' : 
    any2True?'bothSensor':'bothSensor';
    var svgData = './assets/logos/' + logo + '.png';;
    // 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg);
    return svgData;
  }
  hasTwoTrueProperties(obj: any): boolean {
    const values = Object.values(obj);
    const trueCount = values.filter(value => value === true).length;
    return trueCount >= 2;
  }
  polygonCreated($event) {
    if(this.gridObj.Id){
      this.gridObj = {};
    }
    if (this.polygon) {
      this.polygon.setMap(null);
    }
    this.polygon = $event;
    this.addPolygonChangeEvent(this.polygon);
    google.maps.event.addListener(this.polygon, 'coordinates_changed', function (index, obj) {
      // Polygon object: yourPolygon
      console.log('coordinates_changed');
    });
    this.showDrawActions = true;


  }

  getPaths() {
    console.log("get path");
    if (this.polygon) {
      const vertices = this.polygon.getPaths().getArray()[0];
      let paths = [];
      vertices.getArray().forEach(function (xy, i) {
        // console.log(xy);
        let latLng = {
          lat: xy.lat(),
          lng: xy.lng()
        };
        paths.push(latLng);
      });
      return paths;
    }
    return [];
  }
  addPolygonChangeEvent(polygon) {
    var me = polygon,
      isBeingDragged = false,
      triggerCoordinatesChanged = function () {
        // Broadcast normalized event
        google.maps.event.trigger(me, 'coordinates_changed');
      };

    // If  the overlay is being dragged, set_at gets called repeatedly,
    // so either we can debounce that or igore while dragging,
    // ignoring is more efficient
    google.maps.event.addListener(me, 'dragstart', function () {
      isBeingDragged = true;
    });

    // If the overlay is dragged
    google.maps.event.addListener(me, 'dragend', function () {
      triggerCoordinatesChanged();
      isBeingDragged = false;
    });

    // Or vertices are added to any of the possible paths, or deleted
    var paths = me.getPaths();
    paths.forEach(function (path, i) {
      google.maps.event.addListener(path, "insert_at", function () {
        triggerCoordinatesChanged();
      });
      google.maps.event.addListener(path, "set_at", function () {
        if (!isBeingDragged) {
          triggerCoordinatesChanged();
        }
      });
      google.maps.event.addListener(path, "remove_at", function () {
        triggerCoordinatesChanged();
      });
    });
  }
  editZone(zone: any) {
    this.gridObj = {};
    if(this.polygon){
      this.resetGrid();
    }
    this.routeGridObj = zone;
    this.gridObj['name'] = zone['Name'];
    this.gridObj['Id'] = zone['Id'];
    this.gridObj['orgId'] = zone['OrganizationId'];
    this.gridObj['portalId'] = zone['PortalID'];
    this.gridObj['truckTypeId'] = zone['TruckTypeId'];
    this.gridObj['coordinates'] = zone['Coordinates'];
    if (this.reportScreenType === 'street') {
      this.gridObj['fillColor'] = zone['FillColor'];
    }
    if (this.reportScreenType === 'grid') {
      this.gridObj['polygonFillColor'] = zone['PolygonFillColor'];
      this.gridObj['cellFillColor'] = zone['CellFillColor'];
    }
  }
  async deleteAllRoutes() {
    var url = 'RouteNonGis?orgId=' + this.orgId + '&portalId=' + this.selectedPortal;
    await this.apiService.deleteData(url).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar("All zones deleted successfully!", "OK")
        this.getRoutesPolygonData();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async deleteAllOldRoutes() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Please confirm that you\'d like to delete all routes:?: \n\n '
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.deleteAllRoutes()
      }
    });
  }
  showZoneCreationInstructions() {
    var title = 'Stoppage Report';

    const modalData = {};
    modalData['helpPdfS3Url'] = this.userManualData.ZoneCreationManual[0];
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: "Instructions for zone creation",
        data: modalData,
        orgId: this.orgId,
        type: 'helpZoneCreate'
      },
      minWidth: 150
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
    });
  }
  async getInstructionsManuals() {
    var url = 'userManualS3Url';
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        this.userManualData = res;
        this.getRoutesPolygonData();
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
      }
    )
  }
  downloadPdf() {
    this.apiService.downloadPdf(this.userManualData.ZoneCreationManual[0]).subscribe(
      (data: Blob) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'zone_create_user_manual.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error => {
        console.error('Error downloading PDF:', error);
      }
    );
  }
  async getselectedPortalPolygons(portalId: any) {
    this.selectedPortal = Number(portalId);
    var selectedPortalObj = this.allPortals.find(obj => {
      return obj.Id === this.selectedPortal;
    })
    console.log(selectedPortalObj)

    if (selectedPortalObj['SmartPaths'] !== 1) {
      if (this.GIS === 0) {
        this.getRoutesPolygonData();
      }
    }
    if (selectedPortalObj['SmartPaths'] === 1) {
      if (this.GIS === 0) {
        this.getSmartpathPolygonData();
      }
    }
  }
  copyZones(getPolygonData) {
    var title = 'Copy Zone';

    const modalData = {};
    modalData['allPortals'] = this.allPortals.filter(portal => {
      return (portal.Id !== this.selectedPortal && portal.Id !== 0)
    });
    modalData['currentPortalId'] = Number(this.selectedPortal);
    // modalData['currentZones'] = this.allGridList;
    modalData['currentZone'] = getPolygonData;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        data: modalData,
        orgId: this.orgId,
        type: 'copyZones'
      },
      minWidth: 150
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
    });
  }
  returnNonGisPortals() {
    var portals = this.allPortals.filter(portal => {
      return this.isSmartPaths ? portal.SmartPaths === 1 : portal.SmartPaths === 0
    })
    return portals
  }
  async getTransportRoutes() {
    var url = 'transport?trackRouteBus=false'
    var payload = {
      "orgId": Number(this.orgId),
      "portalId": this.selectedPortal,
      "transportRouteId": [0]
    }
    this.transRoutedropdownSettings = {
      idField: 'Id',
      textField: 'RouteName',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res)
        this.transportRoutesList = this.allTrType.concat(res);
        this.selectedRoute = this.transportRoutesList[0];
        this.reportData['RouteId'] = [];
        this.reportData['RouteId'].push(this.transportRoutesList[0]);
        this.getTruckTypes(this.selectedPortal);
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  uploadFile() {
    this.busReport = [];
    this.newRoute['portalId'] = this.selectedPortal
    this.showUploadAssign = true;
    this.showUploadForm = true;
  }
  cancelUpload() {
    this.showUploadAssign = false;
    this.showAssignForm = false;
    this.showUploadForm = false;
    this.newRoute['geojson'] = {};
    this.getTransportRoutes();
  }
  assignAsset(getRoute: any) {
    this.truckListExceptAll = [];
    this.selectedTruck = {};
    this.selectedTruckType = '';
    this.showUploadAssign = true;
    this.showAssignForm = true;
    this.selectedRoute = getRoute;
  }
  async selectRoute(getRoute: any) {
    this.routeGjson = {};
    this.busStopLists = [];
    console.log(getRoute);
    this.selectedRoute = getRoute;

  }
  async splitStreetsFromGeojson(data, ind: number) {
    this.showRouteStreets = false;
    var routeGjson = {
      type: "FeatureCollection",
      features: []
    }
    if (data['features'] && data['features'].length > 0) {

      data['features'].map((feature: any) => {
        if (feature.geometry.type === "LineString") {
          routeGjson.features.push(feature)
        }
      })
      this.busReport[ind].routeGJSON = routeGjson
    }
    console.log(this.busReport)
  }
  getTransportPortals() {
    var trPortals = this.allPortals.filter((portal: any) => {
      return portal.IsTransportHub
    })
    return trPortals
  }
  async searchTransport() {
    this.searchLoading = true;
    this.busReport = [];
    this.busStopLists = [];
    // var routeId = this.selectedRoute['Id'] === 'all'?0:this.selectedRoute['Id'];
    // var assetId = this.reportData['TruckId'][0].Id === 'all'?0:this.reportData['TruckId'][0].Id;
    var assetId = []
    this.reportData['TruckId'].map(truck => {
      assetId.push(truck.Id !== 'all' ? truck.Id : 0)
    })
    var routeId = []
    this.reportData['RouteId'].map(truck => {
      routeId.push(truck.Id)
    })
    var url = 'transport?trackRouteBus=true';
    var payload = {
      "orgId": this.orgId,
      "portalId": this.selectedPortal,
      "transportRouteId": routeId,
      "includeBusDetail": true,
      "transportBusId": assetId
    }
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res)
        this.busReport = res;
        this.busStopLists = res.stops;
        this.busReport.forEach((value, index) => {
          value.routeGJSON = {};
          if (value.transportRouteS3Url !== '') {
            this.httpClient.get(value.transportRouteS3Url).subscribe(async data => {
              console.log(data)
              await this.splitStreetsFromGeojson(data, index);
            })
          }
        });
        this.searchLoading = false;
      }, (error) => {
        this.searchLoading = false;
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  lineStringStyleBus(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');
    const strokeOpacity = feature.getProperty('strokeOpacity');

    // #b99885 brown
    return {
      strokeColor: strokeColor ? strokeColor : '#1913E6',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: 4,
      strokeOpacity: strokeOpacity ? strokeOpacity : 0.7
    };
  }
  getbusDetails(busDetais: any, routeI: number, busI: number) {
    console.log(busDetais)
    var url = 'transport?timeToReachNextStop=true';
    var assetId = []
    this.reportData['TruckId'].map(truck => {
      assetId.push(truck.Id !== 'all' ? truck.Id : 0)
    })
    var payload = {
      "orgId": Number(this.orgId),
      "portalId": this.selectedPortal,
      "transportRouteId": busDetais.transportRouteId,
      "busId": busDetais.TruckId
      // "busId": assetId
    }
    console.log(this.busReport)
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        if (Object.keys(res).length !== 0) {
          var cHours = new Date().getHours();
          var cMinutes = new Date().getMinutes();
          var cSeconds = new Date().getSeconds();
          console.log("Current time is "+new Date())
          console.log("Current time is "+cHours, cMinutes, cSeconds)
          for(let bus in res){
            res[bus]['arrivalTime'] = "";
            const newTime = new Date();
            newTime.setHours(newTime.getHours() + res[bus]['durationHr']);
            newTime.setMinutes(newTime.getMinutes() + res[bus]['durationMin']);
            // newTime.setSeconds(newTime.getSeconds() + res[bus]['durationSec']);
            
            res[bus]['arrivalTime'] = this.datepipe.transform(newTime, 'yyyy-M-dd HH:mm');
          }
          this.busReport[routeI]['bus'][busI]['nextEta'] = Object.entries(res);
          console.log(this.busReport)
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }

  getbusStopDetails(busStopDetails: any, routeI: number, stopI: number) {
    console.log(busStopDetails)
    var url = 'transport?findBusArrivalTime=true';
    var assetId = []
    this.reportData['TruckId'].map(truck => {
      assetId.push(truck.Id !== 'all' ? truck.Id : 0)
    })
    var payload = {
      "orgId": Number(this.orgId),
      "portalId": this.selectedPortal,
      "transportRouteId": busStopDetails.TransportRouteId,
      "stopId": busStopDetails.Id,
      "busId": assetId
    }
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        if (Object.keys(res).length !== 0) {
          var cHours = new Date().getHours();
          var cMinutes = new Date().getMinutes();
          var cSeconds = new Date().getSeconds();
          console.log("Current time is "+new Date())
          console.log("Current time is "+cHours, cMinutes, cSeconds)
          for(let bus in res){
            res[bus]['arrivalTime'] = "";
            const newTime = new Date();
            newTime.setHours(newTime.getHours() + res[bus]['durationHr']);
            newTime.setMinutes(newTime.getMinutes() + res[bus]['durationMin']);
            // newTime.setSeconds(newTime.getSeconds() + res[bus]['durationSec']);
            
            res[bus]['arrivalTime'] = this.datepipe.transform(newTime, 'yyyy-MM-ddTHH:mm:ss');
          }
          this.busReport[routeI]['stops'][stopI]['eta'] = Object.entries(res);
          console.log(this.busReport)
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  fileChangeEvent(event: any) {
    this.newRoute['geojson'] = {};
    const file: File = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const result = reader.result as string;
        const jsonData = JSON.parse(result);

        console.log(jsonData);
        var LineStringObjects = jsonData.features.filter((feature: any) => {
          return feature.geometry.type === "LineString"
        })
        var pointObjects = jsonData.features.filter((feature: any) => {
          return feature.geometry.type === "Point"
        })
        var pointObjectsWithCorrectproperties = jsonData.features.filter((feature: any) => {
          return (feature.geometry.type === "Point" && feature.properties.name && feature.properties.order)
        })
        console.log(LineStringObjects, pointObjects, pointObjectsWithCorrectproperties);
        if(LineStringObjects.length >0 && pointObjects.length > 0 && pointObjects.length === pointObjectsWithCorrectproperties.length){
          this.helper.openSnackBar("Uploading route is valid.","Ok")
          this.newRoute['geojson'] = jsonData;
        }
        if(LineStringObjects.length === 0){
          this.helper.openSnackBar("Uploading route is invalid. Streets not available on this route. Please add some streets on this route and try again.","Ok")
        }
        if(pointObjects.length === 0){
          this.helper.openSnackBar("Uploading route is invalid. Bus stops not available on this route. Please add some Bus stops on this route and try again.","Ok")
        }
        if(pointObjects.length > 0 && pointObjects.length !== pointObjectsWithCorrectproperties.length){
          this.helper.openSnackBar("Uploading route is invalid. On Bus stop(s) properties 'order' & 'name' details not available on this route. Please add 'order' & 'name' (*case sensitive) details on all bus stops and try again.","Ok")
        }
         // You can use jsonData in your application
      };

      reader.onerror = () => {
        console.error('Error occurred while reading file');
      };
    }
  }
  async createRoute() {
    console.log(this.newRoute)
    var url = 'transport?orgId=' + this.orgId + '&routeColor=' + encodeURIComponent(this.newRoute['color']) + '&portalId=' + this.newRoute['portalId'] + '&routeName=' + this.newRoute['name']+'&type='+this.newRoute['routeType'];
    this.apiService.postNewdataWithQueryParams(url, this.newRoute['geojson']).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar("Route created successfully", "OK")
        this.newRoute['name'] = '';
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async saveRoute() {
    var url = 'transport?mapTransportRouteBus=true';
    var busIds = []
    this.selectedTruck.map((truck) => {
      busIds.push(truck.Id)
    })
    var payload = {
      "orgId": Number(this.orgId),
      "portalId": this.selectedPortal,
      "transportRouteId": this.selectedRoute['Id'],
      "busIds": busIds
    }
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar("Bus assigned successfully", "OK")
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getbusTypes() {
    var bustypes = this.trucksTypesArray.filter((bus) => {
      return bus.Name !== 'all'
    })
    return bustypes
  }
  confirmUnAssign(routeDetails: any, assignedBusDetails: any) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Unassign',
        message: 'Please confirm that you\'d like to unassign this asset "' + assignedBusDetails['Description'] + '" from route "' + routeDetails['RouteName'] + '"'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.unAssignAsset(routeDetails, assignedBusDetails)
      }
    });
  }
  async unAssignAsset(routeDetails: any, assignedBusDetails: any) {
    var url = 'transport?orgId=' + this.orgId + '&portalId=' + this.selectedPortal + '&transportRouteId=' + routeDetails.Id + '&busId=' + assignedBusDetails.TruckId;
    await this.apiService.deleteData(url).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar("Asset unassigned successfully!", "OK")
        this.getTransportRoutes();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  onMonthChange(event) {
    this.calanderData = {}
    // Calculate the number of days in the month
    const numberOfDays = new Date(event.year, event.month + 1, 0).getDate();
    var startDate = event.year + '-' + event.month + '-' + '01'
    var endDate = event.year + '-' + event.month + '-' + numberOfDays
    this.getCalanderDates(startDate, endDate)
  }
  onOpenCalendar(event) {
    const currentDate = new Date(this.StartTime);
    // Get the month (0-indexed) and year
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    // Calculate the number of days in the month
    const numberOfDays = new Date(year, month, 0).getDate();
    var startDate = year + '-' + month + '-' + '01'
    var endDate = year + '-' + month + '-' + numberOfDays
    this.getCalanderDates(startDate, endDate)
  }
  getCalanderDates(startDate, endDate) {
    var url = 'spDataForDate';
    var truckIds = []
    this.reportData['TruckId'].map(truck => {
      truckIds.push(truck.Id)
    })
    var payload = {
      "orgId": Number(this.orgId),
      "portalId": this.selectedPortal,
      "truckId": truckIds.includes('all') ? [0] : truckIds,
      "startDate": startDate,
      "endDate": endDate
    }
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        this.calanderData = res
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getExcludes3Url(routeId) {
    if (this.excludeStreetsData.length === 0) {
      this.showExcludeStreets = false;
      console.log(this.urlOrigin);
      this.activeExcludeStreetZone = routeId;
      // var bucketName = this.urlOrigin.includes("portal") ? "spmapskmz" : "spmapskmzdevva";
      // this.excludeS3Url = 'https://' + bucketName + '.s3.amazonaws.com/Routes/Organization_' + this.orgId + '/Portal_' + this.selectedPortal + '/Route_' + routeId + '.geojson'
      this.apiService.getByUrl('RouteNonGis?orgId='+this.orgId+'&portalId='+this.selectedPortal+'&routeId='+routeId).subscribe((data: any) => {
        this.excludeS3Url = data['s3Url'];
        this.getRoutegeojsonData();
      }, (error) => {
        this.excludeGeojsonData = {};
        this.OriginalexcludeGeojsonData = {};
        console.log(error);
      })
    } else {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm zone change',
          message: 'Please confirm that you\'d like to exit from include/exclude streets of this zone:? \n\n '
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.showExcludeStreets = false;
          this.excludeStreetsData = [];
          // var bucketName = this.urlOrigin.includes("portal") ? "spmapskmz" : "spmapskmzdevva";
          // this.excludeS3Url = 'https://' + bucketName + '.s3.amazonaws.com/Routes/Organization_' + this.orgId + '/Portal_' + this.selectedPortal + '/Route_' + routeId + '.geojson'
          this.apiService.getByUrl('RouteNonGis?orgId='+this.orgId+'&portalId='+this.selectedPortal+'&routeId='+routeId).subscribe((data: any) => {
            this.excludeS3Url = data['s3Url'];
            this.getRoutegeojsonData();
          }, (error) => {
            this.excludeGeojsonData = {};
            this.OriginalexcludeGeojsonData = {};
            console.log(error);
          })
          this.getRoutegeojsonData();
        } else {
          this.selectedExcludeRoute = this.activeExcludeStreetZone;
        }
      });
    }
  }
  excludeDalaLayerClicked(event: any) {
    console.log(event)
    var isExist = false;
    var existIndex: any = null;
    const featureId = event.feature.getId();
    this.excludeStreetsData.forEach((element, index) => {
      if (element.uvKeyId === featureId) {
        isExist = true;
        existIndex = index;
      }
    });
    // const featureHighway = event.feature.getProperty('highway');
    const featureStreetName = event.feature.getProperty('name');
    var streetObj = {
      uvKeyId: featureId,
      name: featureStreetName
    }
    if(isExist){
      this.excludeStreetsData.splice(existIndex, 1);
    }else{
      this.excludeStreetsData.push(streetObj);
    }
    this.previewWithReloadStreet();
    // this.changeStreetColor(featureId);
  }
  zoneByStreetDataLayerClick(event: any) {
    console.log(event)
    var isExist = false;
    var existIndex: any = null;
    const featureId = event.feature.getId();
    this.zoneByStreetsData.forEach((element, index) => {
      if (element.uvKeyId === featureId) {
        isExist = true;
        existIndex = index;
      }
    });
    // const featureHighway = event.feature.getProperty('highway');
    const featureStreetName = event.feature.getProperty('name');
    var streetObj = {
      uvKeyId: featureId,
      name: featureStreetName
    }
    if(isExist){
      this.zoneByStreetsData.splice(existIndex, 1);
    }else{
      this.zoneByStreetsData.push(streetObj);
    }
    this.previewZoneByStreetWithReloadStreet()
  }
  async includeExcludeStreetsKmz() {
    var url = 'RouteNonGis?excludeStreet=true&routeId=' + this.selectedExcludeRoute+'&type=kmz';
    var payload = {
      "orgId": this.orgId,
      "portalId": this.selectedPortal,
      "uvKeyIds": []
    }
    if(this.streetActionType === 'exclude'){
      this.excludeStreetsData.forEach(element => {
        payload.uvKeyIds.push(element.uvKeyId)
      });
      console.log(payload.uvKeyIds.length,this.excludeGeojsonData.features.length)
    }
    if(this.streetActionType === 'include'){
      if (this.excludeStreetsData.length > 0) {
        var uvIds = [];
        for (let feature of this.excludeStreetsData) {
          var vuKeyId = this.reverseStringInParentheses(feature.uvKeyId);
          uvIds.push(feature.uvKeyId)
          uvIds.push(vuKeyId)
        }
        console.log(this.excludeGeojsonData.features.length)
        this.excludeGeojsonData.features.forEach((obj: any) => {
            if(!uvIds.includes(obj.id)){
              payload.uvKeyIds.push(obj.id)
            }
          });
      }
      console.log(payload.uvKeyIds.length,this.excludeGeojsonData.features.length)
    }
    await this.apiService.updatedata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.includeExcludeStreetsGeojson(payload);
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async includeExcludeStreetsGeojson(payload) {
    var url = 'RouteNonGis?excludeStreet=true&routeId=' + this.selectedExcludeRoute+'&type=geojson';
    await this.apiService.updatedata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar(this.streetActionType+" streets initiated successfully.!", "OK")
        this.excludeStreetsData = [];
        this.getRoutesPolygonData();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getRoutegeojsonData() {
    this.excludeGeojsonData = {};
      this.OriginalexcludeGeojsonData = {};
    this.apiService.getByExternalUrl(this.excludeS3Url).subscribe((data: any) => {
      this.excludeGeojsonData = data;
      this.OriginalexcludeGeojsonData = data;
      this.showExcludeStreets = true;
      console.log(data);
    }, (error) => {
      this.excludeGeojsonData = {};
      this.OriginalexcludeGeojsonData = {};
      console.log(error);
    })
  }
  getAddStreetRoutegeojsonData() {
    this.showAddStreetZoneStreets = false;
    this.selectedZoneGJsonData = {};
      // this.OriginalexcludeGeojsonData = {};
    this.apiService.getByExternalUrl(this.addStreetZoneS3Url).subscribe((data: any) => {
      this.selectedZoneGJsonData = data;
      // this.OriginalexcludeGeojsonData = data;
      this.showAddStreetZoneStreets = true;
      console.log(data);
    }, (error) => {
      this.excludeGeojsonData = {};
      this.OriginalexcludeGeojsonData = {};
      console.log(error);
    })
  }
  confirmIncludeExclude() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Exclude',
        message: 'Please confirm that you\'d like to '+this.streetActionType+' selected streets from this zone? \n\n '
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.includeExcludeStreetsKmz()
      }
    });
  }
  deleteItem(feature: any) {
    this.excludeStreetsData.forEach((element, index) => {
      if (element.uvKeyId == feature.uvKeyId) {
        this.excludeStreetsData.splice(index, 1);
      }
    });
    this.previewWithReloadStreet();
  }
  deletestreetItem(feature: any) {
    this.zoneByStreetsData.forEach((element, index) => {
      if (element.uvKeyId == feature.uvKeyId) {
        this.zoneByStreetsData.splice(index, 1);
      }
    });
    this.previewZoneByStreetWithReloadStreet();
  }
  reverseStringInParentheses(inputString: string): string {
    return inputString.replace(/\(([^,]+), ([^,]+), ([^)]+)\)/, '($2, $1, $3)');
  }
  previewWithReloadStreet() {
    this.showExcludeStreets = false;
    this.excludeGeojsonData.features.forEach(element => {
      return element['properties']['stroke'] = "#b99885"
    });
    if (this.excludeStreetsData.length > 0) {
      for (let feature of this.excludeStreetsData) {
        //Find index of specific object using findIndex method.   
        var vuKeyId = this.reverseStringInParentheses(feature.uvKeyId);
        console.log(feature.uvKeyId, vuKeyId)
        var objIndex = this.excludeGeojsonData.features.findIndex(((obj: any) => obj.id === feature.uvKeyId));
        var revobjIndex = this.excludeGeojsonData.features.findIndex(((obj: any) => obj.id === vuKeyId));
        if (objIndex && objIndex !== -1) {
              this.excludeGeojsonData.features[objIndex]['properties']['stroke'] = this.excludeStreetColor;
        }
        if (revobjIndex && revobjIndex !== -1) {
          this.excludeGeojsonData.features[revobjIndex]['properties']['stroke'] = this.excludeStreetColor;
        }
      }
    }
    setTimeout(() => 
      {
        this.showExcludeStreets = true;
      }, 100); // 6000 is 1 second
  }
  getRouteName(routeId){
    var routeObj = this.transportRoutesList.find(route => {return route.Id === routeId})
    return routeObj && routeObj.RouteName?routeObj.RouteName: 'N/A'
  }
  async getPriorityData() {
    this.showPriorityStreet = false;
    var url = 'changeStreetHighway?orgId='+this.orgId+'&portalId='+this.selectedPortal;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.streetPrioritiesData = data;
      
      if(this.streetPrioritiesData.streetPriorityS3Url !== ''){
        this.message = "Streets loading... ";
        this.getgeojsonDataByUrl();
        // this.showMap = true;
      } else {
        this.message = "Priority street not loaded for this portal "+this.selectedPortal;
        this.showMap = false;
        // this.showStreet = false;
      }
    }, (error) => {
      console.log(error);
    })
}
getgeojsonDataByUrl(){
  this.apiService.getByExternalUrl(this.streetPrioritiesData.streetPriorityS3Url).subscribe((data: any) => {
    data.features.map((feature) => {
      feature.properties.stroke = '#1913E6'
      return feature
    })
    this.priorityGeojsonData = data;
    this.OriginalpriorityGeojsonData = data;
    this.showMap = true;
    this.showPriorityStreet = true;
    console.log(data);
  }, (error) => {
    console.log(error);
    this.helper.openSnackBar(error, "OK")
  })
}
onZoneByStreettoggleChange(event){
  console.log(event)
  if(this.polygon){
    this.resetGrid();
  }
  this.showZoneByStreet = event.checked;
  if(this.showZoneByStreet){
    this.showExclude = false;
    this.showDrawActions = false;
    this.getPriorityData();
  }
}
getSelectedAddStreetRoute(selectedAddStreetRouteId){
  var selectedRoute = this.filteredGridList.filter(route => {
    return route.Id === Number(selectedAddStreetRouteId)
  })
  this.copyZones(selectedRoute[0])
}
onincludeToggleChange(event){
  console.log(event)
  if(this.polygon){
    this.resetGrid();
  }
  this.showExclude = event.checked;
  if(this.showExclude){
    this.showZoneByStreet = false;
  }
}
previewZoneByStreetWithReloadStreet() {
  this.showPriorityStreet = false;
  // this.priorityGeojsonData.features.forEach(element => {
  //   return element['properties']['stroke'] = "#b99885"
  // });
  if (this.zoneByStreetsData.length > 0) {
    for (let feature of this.zoneByStreetsData) {
      //Find index of specific object using findIndex method.   
      var vuKeyId = this.reverseStringInParentheses(feature.uvKeyId);
      console.log(feature.uvKeyId, vuKeyId)
      var objIndex = this.priorityGeojsonData.features.findIndex(((obj: any) => obj.id === feature.uvKeyId));
      var revobjIndex = this.priorityGeojsonData.features.findIndex(((obj: any) => obj.id === vuKeyId));
      if (objIndex && objIndex !== -1) {
            this.priorityGeojsonData.features[objIndex]['properties']['stroke'] = this.zoneByStreetColor;
      }
      if (revobjIndex && revobjIndex !== -1) {
        this.priorityGeojsonData.features[revobjIndex]['properties']['stroke'] = this.zoneByStreetColor;
      }
    }
  }
  setTimeout(() => 
    {
      this.showPriorityStreet = true;
    }, 100); // 6000 is 1 second
}
async createZoneByStreets(){
  var url = 'RouteNonGis?routeBySelectStreets=true ';
  this.gridObj.orgId = Number(this.orgId);
  this.gridObj.orgName = this.orgName;
  this.gridObj.portalId = this.selectedPortal;
  this.gridObj.userEmail = this.userEmail;
  
  if (this.reportScreenType === 'street') {
    this.gridObj["uvKeyIds"] = [];
    this.zoneByStreetsData.map((street) => {
      this.gridObj["uvKeyIds"].push(street.uvKeyId)
    })
  }
  if(this.zoneByStreettype === 'addStreets'){
    if(this.selectedAddStreetRoute !== ''){
      this.gridObj['routeId'] = Number(this.selectedAddStreetRoute)
    } else {
      return
    }
  }
  if(this.zoneByStreettype === 'createZoneByStreet' && this.gridObj['routeId']){
    delete this.gridObj['routeId']
  }
  if (this.gridObj.name !== '' && this.gridObj.gridSize !== 0) {
    this.apiService.postNewdata(url, this.gridObj).subscribe(
      (res: any) => {
        console.log(res)
        this.helper.openSnackBar("Successfully creating/adding streets on zone " + this.gridObj.name + ".", "OK");
        this.gridObj.name = '';
        this.zoneByStreetsData = [];
        if (this.reportScreenType === 'street') {
          this.showDrawActions = false;
          this.getRoutesPolygonData();
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  } else {
    this.helper.openSnackBar("Zone all inputs mandatory!", "OK")
  }
}
getAddStreetS3Url(routeId) {
    this.showExcludeStreets = false;
    console.log(this.urlOrigin);
    this.selectedAddStreetRoute = routeId;
    var selectedRoute = this.filteredGridList.filter((route: any)=> {
      return route.Id === Number(routeId)
    })
    this.selectedZoneToCopy = selectedRoute[0];
    if(selectedRoute && selectedRoute[0] && selectedRoute[0].Name){
      this.gridObj.name = selectedRoute[0].Name;
      this.gridObj.Id = selectedRoute[0].Id;
    }
    this.apiService.getByUrl('RouteNonGis?orgId='+this.orgId+'&portalId='+this.selectedPortal+'&routeId='+routeId).subscribe((data: any) => {
      this.addStreetZoneS3Url = data['s3Url'];
      if(this.addStreetZoneS3Url !== ''){
        this.getAddStreetRoutegeojsonData();
      } else {
        this.helper.openQuickSnackBar("Selected zone S3 url empty!","Ok")
        this.selectedZoneGJsonData = {};
      }
    }, (error) => {
      this.excludeGeojsonData = {};
      this.OriginalexcludeGeojsonData = {};
      console.log(error);
    })
}
getIsHighlightTruckInZone(){
  var selectedPortalObj = this.allPortals.filter((portal: any) => {
    return portal.Id === this.selectedPortal
  })
  if(selectedPortalObj[0]['TrucksInZone']){
    this.highlightTruckInZone = true;
    this.showHighlightTruckInZone = true;
  }else {
    this.highlightTruckInZone = false;
    this.showHighlightTruckInZone = false;
  }
  // return selectedPortalObj[0]['TrucksInZone']
}
getgridKmzZIndexValue(){
  return Number(this.gridKmlzIndex)
}
trucksInZoneChange(ob: MatCheckboxChange) {
  console.log(ob.checked);
  this.highlightTruckInZone = ob.checked;
}
moveMarker(targetLat: number, targetLng: number, callback: () => void) {
  const deltaLat = (targetLat - this.markerLat) / this.truckReplaySpeed;
    const deltaLng = (targetLng - this.markerLng) / this.truckReplaySpeed;
    let steps = 0;

    const interval = setInterval(() => {
      if (steps < this.truckReplaySpeed && this.isReplayStarted) {
        this.markerLat = this.markerLat?this.markerLat + deltaLat:targetLat + deltaLat;
        this.markerLng = this.markerLng?this.markerLng + deltaLng:targetLng + deltaLng;
        steps++;
      } else {
        clearInterval(interval);
        callback();
      }
    }, 50); // Adjust this value for speed control
}
async replayTruck(){
  this.isReplayStarted = true;
  this.markerLat = this.replayTruckPoints[0].lat;
  this.markerLng = this.replayTruckPoints[0].lng;
  console.log(this.replayTruckPoints.length)
    let index = 1;
      const moveToNextLocation = () => {
        if (index < this.replayTruckPoints.length - 1 && this.isReplayStarted) {
          index++;
          this.moveMarker(this.replayTruckPoints[index].lat, this.replayTruckPoints[index].lng, moveToNextLocation);
        }
        if (index === this.replayTruckPoints.length - 1 && this.isReplayStarted) {
          this.stopReplayTruck();
        }
      };
      moveToNextLocation(); 
}
replaySpeedUp(){
  if(this.truckReplaySpeed > 20){
  this.truckReplaySpeed -= 20;
  }else {
    if(this.truckReplaySpeed > 10){
      this.truckReplaySpeed -= 10;
    }
  }
}
replaySpeeddown(){
  
    this.truckReplaySpeed += 20;
}
stopReplayTruck(){
  this.isReplayStarted = false;
  this.truckReplaySpeed = 100;
}
}
// testing for re-deployment